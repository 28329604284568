import { IMLayout, SpacingProps, SpacingType, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Platform, Pressable as RNPressable, PressableProps as RNPressableProps, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { AnimatedStyle } from 'react-native-reanimated'

import AnimatedView from './AnimatedView'

export interface PressableProps extends RNPressableProps {
  spacing?: SpacingProps
  spacingType?: SpacingType
  containerStyle?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>
  noContentStyling?: boolean
  disableEffects?: boolean
}

const Pressable = memo(function Pressable({
  spacing,
  disableEffects,
  spacingType,
  containerStyle,
  noContentStyling,
  disabled,
  ...pressableProps
}: PressableProps) {
  const { theme } = useTheme()

  return (
    <AnimatedView spacing={spacing} spacingType={spacingType} style={[containerStyle, styles.animatedView]}>
      <RNPressable
        {...pressableProps}
        // @ts-ignore it works but we need to discover how to implement it on ts
        style={({ pressed, hovered }) => [
          {
            backgroundColor: disableEffects
              ? 'transparent'
              : (Platform.OS === 'web' ? hovered : pressed)
                ? theme.button.pressedOpacity
                : 'transparent',
            opacity: disableEffects ? 1 : pressed || disabled ? 0.5 : 1,
          },
          !noContentStyling && styles.pressable,
          pressableProps.style,
        ]}
      />
    </AnimatedView>
  )
})

export default Pressable

const styles = StyleSheet.create({
  pressable: {
    justifyContent: 'center',
    borderRadius: IMLayout.borderRadius,
    padding: IMLayout.horizontalMargin,
  },
  animatedView: {},
})
