import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Quality } from '../../apis/apiResponseTypes'
import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types/types'
import QualityCard from '../cards/QualityCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Quality | undefined) => void
  required?: boolean
  selectedQualityId?: number
  error?: boolean
}

export default function TypologySelector({ onChange, selectedQualityId, ...props }: Props) {
  const { i18n } = useLanguage()

  const { qualities, organizationId, setQualities } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(qualities, search, ['qualityName', 'qualityId', 'qualityCode'])

  const render = ({ item }: SectionListRenderItemInfo<Quality, ListSection<Quality>>, onPress?: () => void) => {
    return <QualityCard quality={item} onPress={onPress} selected={item.qualityId === selectedQualityId} spacing={['bottom', 'horizontal']} />
  }

  const handleOnChange = (newValue: Quality | undefined) => {
    onChange(newValue)
  }

  const handleSave = (inputValue: string) => {
    saveQuality(inputValue).catch(error => {
      console.error('Failed to save customer', error)
    })
  }

  const saveQuality = async (inputValue: string) => {
    try {
      if (organizationId === undefined) return
      await api.postQuality({ qualityName: inputValue, organizationId: organizationId })
      const res = await api.getQualities({ organizationId: organizationId })
      setQualities(res)
    } catch (error) {
      console.error('Failed to save customer', error)
    }
  }

  const selectedQuality = useMemo(() => qualities?.find(elem => elem.qualityId === selectedQualityId), [qualities, selectedQualityId])

  return (
    <SelectInput
      id={'qualityId'}
      data={filteredData}
      value={selectedQuality}
      loading={false}
      refresh={undefined}
      title={i18n.t('QUALITY')}
      screenTitle={i18n.t('QUALITY')}
      noDataMessage={i18n.t('NO_QUALITY')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.qualityName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      onSave={(inputValue: string) => handleSave(inputValue)}
      searchField="qualityName"
      {...props}
    />
  )
}
