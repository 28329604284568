import { CardProps, IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { Sawmill } from '../../apis/apiResponseTypes' // Assuming Sawmill type is defined
import CardLeftTitle from '../CardLeftTitle'

export type SawmillCardProps = { sawmill: Sawmill } & CardProps

export default function SawmillCard({ sawmill, selected, ...cardProps }: SawmillCardProps) {
  const { theme } = useTheme()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{sawmill.sawmillName}</IM.Text>
    </IM.Card>
  )
}
