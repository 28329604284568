import { CardProps, IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { Measurer } from '../../apis/apiResponseTypes'
import CardLeftTitle from '../CardLeftTitle'

export type MeasurerCardProps = { measurer: Measurer } & CardProps

export default function MeasurerCard({ measurer, selected, ...cardProps }: MeasurerCardProps) {
  const { theme } = useTheme()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{measurer.measurerName}</IM.Text>
    </IM.Card>
  )
}
