/* eslint-disable @typescript-eslint/no-explicit-any */

import { IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import type { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Pressable, StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import { TableHeaderIconData } from '../../types/types'
import TableUtils from '../../utils/TableUtils'
import Cell from '../cells/Cell'
import EditDeleteCell from '../cells/EditDeleteCell'
import SelectionCell from '../cells/SelectionCell'

interface RowProps<T> {
  data: TableHeaderIconData<T>[] | string[]
  lastEmptyCell?: boolean
  firstEmptyCell?: boolean
  hasEditAndDelete?: boolean
  onDeletePress?: () => void
  onEditPress?: () => void
  onPress?: () => void
  hasSelection?: boolean
  isSelected?: boolean
  onSelection?: () => void
  allItemsSelected?: boolean
  onSelectAll?: () => void
  height?: number
  widthArray?: number[]
  flexArray?: number[]
  selectable?: boolean
  cellStyle?: StyleProp<ViewStyle>
  rowStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  cellTextStyle?: (item: any) => StyleProp<TextStyle>
}

// Wrap the Row component with React.memo for memoization
const Row = React.memo(
  <T,>({
    data,
    lastEmptyCell,
    firstEmptyCell,
    hasEditAndDelete,
    onDeletePress,
    onEditPress,
    onPress,
    cellStyle,
    rowStyle,
    height,
    widthArray,
    flexArray,
    textStyle,
    cellTextStyle,
    selectable,
    hasSelection,
    isSelected,
    onSelection,
    allItemsSelected,
    onSelectAll,
    ...props
  }: RowProps<T>) => {
    const { theme } = useTheme<ThemeColorExpanded>()
    const tableWidth = widthArray ? TableUtils().sum(widthArray) : '100%'
    const tableFlex = flexArray ? TableUtils().sum(flexArray) : 1
    const hasDivider = hasEditAndDelete || lastEmptyCell
    const selectedBackground = hasSelection && isSelected

    if (!data) return <></>

    return (
      <Pressable
        onPress={onPress}
        style={[
          {
            width: tableWidth,
            flex: tableFlex,
            height: height ?? 'auto',
          },
          IMLayout.flex.row,
          styles.row,
          rowStyle,
          selectedBackground && { backgroundColor: theme.primary },
        ]}>
        {firstEmptyCell && (
          <SelectionCell
            numItems={data.length + 1}
            height={height}
            onSelection={onSelectAll}
            style={[styles.divider, { borderRightColor: theme.table.divider }, styles.cell, cellStyle]}
            isSelected={allItemsSelected}
          />
        )}
        {hasSelection && (
          <SelectionCell
            numItems={data.length + 1}
            height={height}
            onSelection={onSelection}
            style={[styles.divider, { borderRightColor: theme.table.divider }, styles.cell, cellStyle]}
            isSelected={isSelected}
          />
        )}
        {data.map((item, idx) => {
          const flex = flexArray?.[idx]
          const width = widthArray?.[idx]

          let cellData = ''
          if (typeof item === 'string') {
            cellData = item
          } else {
            cellData = item.title
          }

          const cellIcon = typeof item !== 'string' ? item.rightIcon : undefined
          const cellIconPress = typeof item !== 'string' ? item.rightIconPress : undefined
          const cellIconColor = typeof item !== 'string' ? item.rightIconColor : undefined

          return (
            <Cell
              key={idx}
              text={cellData}
              width={width}
              height={height}
              rightIcon={cellIcon}
              selectable={selectable}
              onRightIconPress={cellIconPress}
              iconColor={cellIconColor}
              flex={flex}
              cellContainerStyle={[
                hasDivider || !TableUtils().isLastItem(idx, data.length) ? styles.divider : undefined,
                { borderRightColor: theme.table.divider },
                styles.cell,
                cellStyle,
              ]}
              textStyle={[cellTextStyle && cellTextStyle(item), textStyle]}
              {...props}
            />
          )
        })}
        {hasEditAndDelete && (
          <EditDeleteCell
            numItems={data.length}
            onDeletePress={onDeletePress}
            onEditPress={onEditPress}
            height={height}
            style={[styles.cell, cellStyle]}
          />
        )}
        {lastEmptyCell && <Cell width={80} height={height} />}
      </Pressable>
    )
  }
)

export default Row

const styles = StyleSheet.create({
  row: {
    overflow: 'hidden',
  },
  divider: {
    borderRightWidth: 2,
  },
  cell: {
    justifyContent: 'center',
  },
})
