import { CardProps, IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { Typology } from '../../apis/apiResponseTypes' // Assuming Typology type is defined
import CardLeftTitle from '../CardLeftTitle'

export type TypologyCardProps = { typology: Typology } & CardProps

export default function TypologyCard({ typology, selected, ...cardProps }: TypologyCardProps) {
  const { theme } = useTheme()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{typology.typologyName}</IM.Text>
    </IM.Card>
  )
}
