import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useEffect, useState } from 'react'
import { NativeSyntheticEvent, StyleSheet, TextInputEndEditingEventData, TextStyle } from 'react-native'

import { TextInputRef, ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'
import Pressable from './Pressable'
import PressableIcon from './PressableIcon'

export type NumericInputProps = Omit<BaseTextInputProps, 'type' | 'value' | 'onChangeText'> &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    disableFastInputs?: boolean
    unit?: IconProp | string
    decimals?: number
    size?: 'small' | 'normal'
    max?: number
    min?: number
    onPlus?: () => void
    onMinus?: () => void
    value?: number | string
    onValueChanged?: (value: number | undefined) => void
  }

const NumberInput = memo(
  forwardRef(function NumberInput(
    {
      title,
      titleFontWeight,
      details,
      editable = true,
      error,
      loading,
      disableFocus,
      disableFastInputs,
      size,
      max,
      min,
      value,
      decimals,
      unit,
      onEndEditing,
      ...textInputProps
    }: NumericInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { onPlus, onMinus, onValueChanged } = textInputProps
    const { theme } = useTheme<ThemeColorExpanded>()
    const [internalValue, setInternalValue] = useState<string>('')

    const disableMinus = min !== undefined ? min >= parseInt(internalValue, 10) : internalValue === '0' || !editable
    const disableMax = (max !== undefined && !!internalValue && parseInt(internalValue, 10) >= max) || !editable ? true : false
    const opacityMinus = disableMinus ? 0.6 : 1
    const opacityMax = disableMax ? 0.6 : 1
    const marginHorizontal = size === 'small' ? 0 : 4

    useEffect(() => setInternalValue(value?.toString().replace('.', ',') ?? ''), [value])

    const handlePress = (pressType: 'add' | 'subtract') => {
      let newValue = parseInt(internalValue, 10)

      if (pressType === 'add') {
        newValue += 1
      } else {
        newValue -= 1
      }

      handleChangeText(newValue.toString())
    }

    const handleChangeText = (text: string) => {
      setInternalValue(text)
      if (text.endsWith(',') || text.endsWith('.')) return
      const parsedValue = appUtils.parseFloatFromText(text)
      if (parsedValue !== undefined && !Number.isNaN(parsedValue) && Number.isFinite(parsedValue)) onValueChanged?.(parsedValue)
    }

    function handleEndEditing(e: NativeSyntheticEvent<TextInputEndEditingEventData>) {
      if (!e.nativeEvent.text) {
        onValueChanged?.(undefined)
      }
      if (min !== undefined || max !== undefined) {
        const parsedValue = appUtils.parseFloatFromText(e.nativeEvent.text)
        if (parsedValue !== undefined && !Number.isNaN(parsedValue) && Number.isFinite(parsedValue)) {
          if (min !== undefined && parsedValue < min) handleChangeText(min.toString())
          if (max !== undefined && parsedValue > max) handleChangeText(max.toString())
        }
      }

      onEndEditing?.(e)
    }

    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput
          ref={ref}
          value={internalValue}
          {...textInputProps}
          onChangeText={handleChangeText}
          onEndEditing={handleEndEditing}
          inputMode="numeric"
          keyboardType="numeric"
          type={decimals ? 'decimal' : 'number'}
          textStyle={[styles.text, textInputProps.style]}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />}
          {!disableFastInputs && (
            <BaseTextInput.LeftIcon
              key={'FastInputIconLeft'}
              borderColor={theme.general.error}
              disableBorder={textInputProps.disableBorderRadius}
              style={[{ backgroundColor: theme.general.error, opacity: opacityMinus }, styles.iconContainer]}>
              <PressableIcon
                disabled={disableMinus}
                icon={['fal', 'minus']}
                size={18}
                color="white"
                iconStyle={{ marginHorizontal }}
                pressableStyle={styles.pressable}
                onPress={() => {
                  onMinus?.()
                  handlePress('subtract')
                }}
              />
            </BaseTextInput.LeftIcon>
          )}
          {!!unit && (
            <BaseTextInput.RightIcon key={'UnitIcon'} disableBorder={disableFastInputs ? undefined : ['bottom', 'top']}>
              {typeof unit === 'string' ? (
                <Pressable disabled>
                  <IM.Text secondary style={{ marginHorizontal }}>
                    {unit}
                  </IM.Text>
                </Pressable>
              ) : (
                <IM.Icon icon={unit} size={18} color={theme.textDetail} iconStyle={{ marginHorizontal }} />
              )}
            </BaseTextInput.RightIcon>
          )}
          {!disableFastInputs && (
            <BaseTextInput.RightIcon
              key={'FastInputIconRight'}
              borderColor={theme.general.info}
              disableBorder={textInputProps.disableBorderRadius}
              style={[{ backgroundColor: theme.general.info, opacity: opacityMax }, styles.iconContainer]}>
              <PressableIcon
                disabled={disableMax}
                icon={['fal', 'plus']}
                size={18}
                color="white"
                iconStyle={{ marginHorizontal }}
                pressableStyle={styles.pressable}
                onPress={() => {
                  onPlus?.()
                  handlePress('add')
                }}
              />
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default NumberInput

// const styles = StyleSheet.create({
//   iconContainer: { paddingHorizontal: 0 },
//   pressable: { borderRadius: 0, flex: 1 },
//   text: { textAlign: 'right' },
// })

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
  },
  input: {
    paddingHorizontal: 0,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    height: 40,
    width: 70,
    marginLeft: 0,
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
  },
  label: {
    flex: 1,
    marginRight: 10,
  },
  iconContainer: {
    paddingHorizontal: 0,
  },
  pressable: {
    borderRadius: 0,
    flex: 1,
  },
  text: {
    textAlign: 'right',
  },
})
