import { DateUtils, Language, useDidUpdate, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo, useRef, useState } from 'react'
import { Platform, TextStyle } from 'react-native'
import { Mask } from 'react-native-mask-input'

import TimeUtils from '../utils/TimeUtils'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'
import DateTimePicker from './DateTimePicker.web'
import PressableIcon from './PressableIcon'

type TextInputProps = Omit<BaseTextInputProps, 'onChangeText'> &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    minimumDate?: Date | string
    maximumDate?: Date | string
    onChangeDate: (date: Date | undefined) => void
    onShow?: (value: boolean) => void
    showCalendarButton?: boolean
  }

export const convertDateToInputFormat = (date: Date, language: Language) => {
  return TimeUtils.format(date.toISOString(), language)
}

export const convertDateFromInputFormat = (date: string) => {
  const [d, m, y] = date.split(/[T :\-/.,]/g)
  return new Date(`${y}-${m}-${d}`)
}

const DateInput = memo(function DateInput({
  title,
  details,
  titleFontWeight,
  editable = true,
  loading,
  disableFocus,
  minimumDate: inMinDate = new Date(1899, 0, 1),
  maximumDate: inMaxDate = new Date(2199, 0, 1),
  onChangeDate,
  value,
  showCalendarButton,
  error: extError,
  ...textInputProps
}: TextInputProps) {
  const { theme } = useTheme()
  const { language } = useLanguage()
  const modalController = useModalController()

  const [formError, setFormError] = useState(false)
  const [text, setText] = useState(value ? convertDateToInputFormat(new Date(value), language) : '')
  const [isDelete, setIsDelete] = useState(value === undefined || value.length === 0 ? false : true)
  const prevText = useRef(text)
  const length = Platform.OS === 'ios' ? (text?.length ?? 0) : 10

  const minimumDate = useMemo(() => DateUtils.dateify(inMinDate), [inMinDate])
  const maximumDate = useMemo(() => DateUtils.dateify(inMaxDate), [inMaxDate])

  useDidUpdate(() => {
    setText(value ? convertDateToInputFormat(new Date(value), language) : '')
  }, [value])

  useDidUpdate(() => {
    text && prevText.current && setIsDelete(text.length < prevText.current.length)
    prevText.current = text

    if (text?.length === 10) {
      const newDate = TimeUtils.manageDateLimit(TimeUtils.manageDateLimit(convertDateFromInputFormat(text), maximumDate, 'max'), minimumDate, 'min')
      handleError(false)
      setText(convertDateToInputFormat(newDate, language))
      onChangeDate(newDate)
    } else if (text?.length === 0) {
      handleError(false)
      onChangeDate(undefined)
    }
  }, [text])

  const handlePickerDate = (newDate: Date) => {
    setText(convertDateToInputFormat(newDate, language))
  }

  const handleError = (error: boolean) => {
    setFormError(error)
  }

  const CustomMask: Mask = (maskValue = '') => {
    const separator = language === 'de' ? '.' : '/'
    const cleanText = maskValue.replace(/\D+/g, '')

    let secondDigitDayMask = /\d/

    if (cleanText.charAt(0) === '0') {
      secondDigitDayMask = /[1-9]/
    }
    if (cleanText.charAt(0) === '3') {
      secondDigitDayMask = /[01]/
    }

    let secondDigitMonthMask = /\d/

    if (cleanText.charAt(2) === '0') {
      secondDigitMonthMask = /[1-9]/
    }
    if (cleanText.charAt(2) === '1') {
      secondDigitMonthMask = /[012]/
    }

    return [/[0-3]/, secondDigitDayMask, separator, /[0-1]/, secondDigitMonthMask, separator, /\d/, /\d/, /\d/, /\d/]
  }

  function handleEndEditing() {
    if (text && text.length < 10 && text.length > 0) {
      const autoCompletedDate = TimeUtils.tryAutoCompleteDate(text, minimumDate, maximumDate)
      if (autoCompletedDate) {
        handlePickerDate(autoCompletedDate)
      } else {
        handleError(true)
      }
    } else {
      handleError(false)
    }
  }

  return (
    <>
      <BaseTextInputProvider editable={editable} error={formError || extError} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput
          {...textInputProps}
          value={text}
          onChangeText={setText}
          type="date"
          mask={CustomMask}
          maskAutoComplete={!isDelete}
          placeholder={TimeUtils.format(minimumDate?.toISOString() ?? new Date().toISOString(), language)}
          pointerEvents="box-only"
          selection={{ start: length, end: length }}
          onBlur={handleEndEditing}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />}
          {showCalendarButton && (
            <BaseTextInput.RightIcon>
              <PressableIcon
                icon={['fal', 'calendar']}
                color={theme.textDetail}
                size={20}
                onPress={() => modalController.show()}
                disabled={!editable}
              />
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
      <DateTimePicker
        date={text?.length === 10 ? convertDateFromInputFormat(text) : new Date()}
        onConfirm={handlePickerDate}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
        mode="date"
        controller={modalController}
      />
    </>
  )
})

export default DateInput
