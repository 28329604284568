import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import { BaseTextInput } from '../../components/baseTextInput/BaseTextInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import PrintTypeSelector from '../../components/selectors/PrintTypeSelector'
import { ThemeColorExpanded } from '../../types'
import { LanguageType, Lot, PrintType } from '../../types/types'
import ExportPrintUtils from '../../utils/exportPrintUtils'

export interface PrintLotViewProps {
  lots?: Lot[]
  onPrint?: () => void
}

export interface PrintLotData {
  type: PrintType
}

const PrintLotView: React.FC<PrintLotViewProps> = ({ onPrint, lots }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [printData, setPrintData] = useState<Partial<PrintLotData>>({
    type: PrintType.DETAIL,
  })
  const [isLoading, setIsLoading] = useState(false)
  const defaultLanguage: LanguageType = { value: 'it', label: 'LANG_IT' }

  function handleChange(value: Partial<PrintLotData>) {
    setPrintData(prev => ({ ...prev, ...value }))
  }

  function isValid() {
    return printData.type !== undefined
  }

  function handlePrintPress() {
    // validate
    if (!isValid()) return

    // create the exportData
    const finalPrintData: PrintLotData = {
      type: printData.type ?? PrintType.DETAIL,
    }

    setIsLoading(true)
    ExportPrintUtils.printLotData(lots ?? [], finalPrintData)
      .then(() => {
        onPrint?.()
      })
      .catch(error => {
        console.error('Error printing:', error)
        alert(i18n.t('ERROR'), i18n.t('PRINT_ERROR'), [
          {
            text: i18n.t('OK'),
            style: 'default',
            onPress: () => {
              return
            },
          },
        ])
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <IM.View spacing={'horizontal'}>
              <BaseTextInput.Title title={i18n.t('SETTINGS')} />
            </IM.View>

            <PrintTypeSelector
              editable
              spacing={'all'}
              onChange={value => handleChange({ type: value?.value })}
              selectedPrintType={ExportPrintUtils.convertPrintTypeToSelector(printData.type)}
            />
          </ScrollViewForm>

          <IM.View style={styles.buttonContainer}>
            <PressableTextIcon
              icon={['fal', 'print']}
              alignIcon="right"
              pressableStyle={styles.webButton}
              style={{ color: theme.button.icon }}
              iconColor={theme.button.icon}
              onPress={() => handlePrintPress()}>
              {i18n.t('PRINT')}
            </PressableTextIcon>
          </IM.View>
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default PrintLotView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
