import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Organization } from '../../apis/apiResponseTypes'
import AnimatedButton from '../../components/AnimatedButton'
import { BaseTextInput } from '../../components/baseTextInput/BaseTextInput'
import GroupSpacer from '../../components/GroupSpacer'
import NumberInput from '../../components/NumberInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import TextInput from '../../components/TextInput'
import { CREATE_USER_BUTTON_ID } from '../../constants/ButtonIds'
import { DEFAULT_ORGANIZATION_CALIBRATION } from '../../constants/constants'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { Address, ThemeColorExpanded, UploadStatus } from '../../types'
import OrganizationUtils from '../../utils/organizationUtils'

const buttonAnimationValue = new Animated.Value(0)

export interface OrganizationCreateViewProps {
  editId?: string
  onCreateOrganization?: (organization: Organization) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

type ValidateOrganizationField = 'organizationName' | 'emails' | 'vatNumber'
type RequiredField = { value: string | undefined; field: ValidateOrganizationField }

const OrganizationCreateView: React.FC<OrganizationCreateViewProps & Props> = ({ editId, onCreateOrganization, onUploadStatus }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const { isAdmin } = useAuthentication()
  const [organization, setOrganization] = useState<Partial<Organization>>({ calibration: DEFAULT_ORGANIZATION_CALIBRATION })
  const [address, setAddress] = useState<Partial<Address>>({})
  const [missingFields, setMissingFields] = useState<ValidateOrganizationField[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<Organization>>({ calibration: DEFAULT_ORGANIZATION_CALIBRATION })
  const [initialAddress, setInitialAddress] = useState<Address>({} as Address)

  useEffect(() => {
    if (editId) {
      loadEditOrganization().catch(console.error)
    }
  }, [editId])

  async function loadEditOrganization() {
    setIsLoading(true)
    await api
      .getOrganization({ organizationId: Number(editId) })
      .then(response => {
        const editOrganization: Organization = {
          organizationId: response.organizationId,
          organizationName: response.organizationName,
          vatNumber: response.vatNumber,
          emails: OrganizationUtils.getEmail(response.emails),
          phoneNumbers: OrganizationUtils.getPhoneNumber(response.phoneNumbers),
          homepageUrl: response.homepageUrl,
          addresses: response.addresses,
          bannerUrl: response.bannerUrl,
          expirationDate: response.expirationDate,
          calibration: response.calibration,
          addDate: response.addDate,
          editDate: response.editDate,
        }
        setOrganization(editOrganization)
        setInitialValue(editOrganization)

        const editAddress: Address = OrganizationUtils.getAddress(response.addresses)
        setAddress(editAddress)
        setInitialAddress(editAddress)
      })
      .catch(console.error)
      .then(() => setIsLoading(false))
  }

  function handleOrganizationChange(value: Partial<Organization>) {
    setOrganization(prev => ({ ...prev, ...value }))
  }

  function handleAddressChange(value: Partial<Address>) {
    setAddress(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields: RequiredField[] = [
      { value: organization.organizationName, field: 'organizationName' },
      { value: organization.emails, field: 'emails' },
      { value: organization.vatNumber, field: 'vatNumber' },
    ]
    const notFilledFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.field)
    setMissingFields(notFilledFields)
    if (notFilledFields.length > 0) {
      onUploadStatus('mandatoryMissing')
    }
    return notFilledFields.length === 0
  }

  function haveValuesChanged() {
    return JSON.stringify(organization) !== JSON.stringify(initialValue) || JSON.stringify(address) !== JSON.stringify(initialAddress)
  }

  useEffect(() => {
    if (haveValuesChanged()) {
      onUploadStatus('waiting')
    }
    if (!showMissingFields) return
    validateForm()
  }, [organization, showMissingFields])

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    handleCreate()
      .catch(console.error)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCreate = async () => {
    //VALIDATION
    // check if all required fields are filled
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    const organizationAddress: Address = {
      line1: address.line1 ?? '',
      line2: address.line2 ?? '',
      city: address.city ?? '',
      zip: address.zip ?? '',
      country: address.country ?? '',
      state: address.state ?? '',
    }

    const organizationBody: Organization = {
      organizationId: organization.organizationId ?? 0,
      organizationName: organization.organizationName ?? '',
      vatNumber: organization.vatNumber ?? '',
      emails: OrganizationUtils.createEmailObject(organization.emails ?? ''),
      phoneNumbers: OrganizationUtils.createPhoneNumberObject(organization.phoneNumbers ?? ''),
      homepageUrl: organization.homepageUrl ?? '',
      addresses: OrganizationUtils.createAddressObject(organizationAddress),
      bannerUrl: organization.bannerUrl ?? '',
      expirationDate: organization.expirationDate ?? new Date().toISOString(),
      editDate: organization.editDate ?? new Date().toISOString(),
      addDate: organization.addDate ?? new Date().toISOString(),
      calibration: organization.calibration ?? DEFAULT_ORGANIZATION_CALIBRATION,
    }

    //UPDATE organization
    if (editId) {
      await api
        .putOrganization(organizationBody)
        .then(response => {
          onCreateOrganization?.(response)
          onUploadStatus('done')
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('ORGANIZATION_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
      return
    }

    //CREATE USER
    await api
      .postOrganization(organizationBody)
      .then(response => {
        onCreateOrganization?.(response)
        onUploadStatus('done')
      })
      .catch(error => {
        console.log(error)
        alert(i18n.t('ERROR'), i18n.t('ORGANIZATION_SAVE_ERROR'), [
          {
            text: i18n.t('OK'),
            style: 'default',
            onPress: () => {
              return
            },
          },
        ])
      })
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <IM.View spacing={'horizontal'}>
              <BaseTextInput.Title title={i18n.t('INFORMATION')} />
            </IM.View>
            <TextInput
              required
              title={i18n.t('NAME')}
              placeholder={i18n.t('NAME')}
              value={organization.organizationName ?? ''}
              onChangeText={value => handleOrganizationChange({ organizationName: value })}
              spacing={'all'}
              error={missingFields.includes('organizationName')}
            />
            <TextInput
              required
              title={i18n.t('VAT')}
              placeholder={i18n.t('VAT')}
              value={organization.vatNumber ?? ''}
              onChangeText={value => handleOrganizationChange({ vatNumber: value })}
              spacing={'all'}
              error={missingFields.includes('vatNumber')}
            />
            <TextInput
              required
              title={i18n.t('EMAIL')}
              placeholder={i18n.t('EMAIL')}
              value={organization.emails ?? ''}
              onChangeText={value => handleOrganizationChange({ emails: value })}
              spacing={'all'}
              error={missingFields.includes('emails')}
            />
            <TextInput
              title={i18n.t('PHONE_NUMBER')}
              placeholder={i18n.t('PHONE_NUMBER')}
              value={organization.phoneNumbers ?? ''}
              onChangeText={value => handleOrganizationChange({ phoneNumbers: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('WEBSITE')}
              placeholder={i18n.t('WEBSITE')}
              value={organization.homepageUrl ?? ''}
              onChangeText={value => handleOrganizationChange({ homepageUrl: value })}
              spacing={'all'}
            />

            <GroupSpacer />
            <IM.View spacing={'horizontal'}>
              <BaseTextInput.Title title={i18n.t('ADDRESS')} />
            </IM.View>
            <TextInput
              title={`${i18n.t('ADDRESS_LINE')} 1`}
              placeholder={`${i18n.t('ADDRESS_LINE')} 1`}
              value={address.line1 ?? ''}
              onChangeText={value => handleAddressChange({ line1: value })}
              spacing={'all'}
            />
            <TextInput
              title={`${i18n.t('ADDRESS_LINE')} 2`}
              placeholder={`${i18n.t('ADDRESS_LINE')} 2`}
              value={address.line2 ?? ''}
              onChangeText={value => handleAddressChange({ line2: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('ADDRESS_ZIP')}
              placeholder={i18n.t('ADDRESS_ZIP')}
              value={address.zip ?? ''}
              onChangeText={value => handleAddressChange({ zip: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('ADDRESS_CITY')}
              placeholder={i18n.t('ADDRESS_CITY')}
              value={address.city ?? ''}
              onChangeText={value => handleAddressChange({ city: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('ADDRESS_STATE')}
              placeholder={i18n.t('ADDRESS_STATE')}
              value={address.state ?? ''}
              onChangeText={value => handleAddressChange({ state: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('ADDRESS_COUNTRY')}
              placeholder={i18n.t('ADDRESS_COUNTRY')}
              value={address.country ?? ''}
              onChangeText={value => handleAddressChange({ country: value })}
              spacing={'all'}
            />

            {isAdmin && (
              <>
                <GroupSpacer />
                <IM.View spacing={'horizontal'}>
                  <BaseTextInput.Title title={i18n.t('BANNER')} />
                </IM.View>
                {/* TODO: make upload and delete of image possible */}
                <TextInput
                  title={i18n.t('BANNER_URL')}
                  placeholder={'https://www.example.com/banner.jpg'}
                  value={organization.bannerUrl ?? ''}
                  onChangeText={value => handleOrganizationChange({ bannerUrl: value })}
                  spacing={'all'}
                />

                <GroupSpacer />
                <NumberInput
                  disableFastInputs
                  value={organization.calibration}
                  onChange={value => handleOrganizationChange({ calibration: Number(value.nativeEvent.text) })}
                  placeholder={i18n.t('CALIBRATION')}
                  title={i18n.t('CALIBRATION')}
                  spacing={'all'}
                />
              </>
            )}
          </ScrollViewForm>

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_USER_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default OrganizationCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
