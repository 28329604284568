import { IM, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import TableUtils from '../../components/table/utils/TableUtils'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { useData } from '../../contexts/DataContext'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import ReductionTypeCreateModal from '../../modals/ReductionTypeCreateModal'
import { ReductionType, ReductionTypeFormula, WoodType } from '../../types/types'
import { ReductionTypeCreateViewProps } from '../ReductionTypeCreate/ReductionTypeCreateView'

export interface FormulasHeaderData {
  woodTypeName: string
  reduction: string
}

export default function ReductionFormulasView({
  setIsLoading,
  isLoading,
}: {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
}) {
  const { i18n } = useLanguage()
  const deleteAlert = useDeleteAlert()
  const {
    reductionTypes,
    loadReductionTypes,
    loadReductionTypesFormula,
    loadWoodTypes,
    reductionTypesLoaded,
    reductionTypeFormulas,
    woodTypes,
    reductionTypesFormulasLoaded,
  } = useData()
  const { isAdmin } = useAuthentication()

  const [reductionTypeSearchValue, setReductionTypeSearchValue] = useState<string | undefined>(undefined)

  const createReductionTypeModal = useModalController<ReductionTypeCreateViewProps>()

  const formulasTableHeader: TableHeaderData<FormulasHeaderData>[] = useMemo(
    () => [
      { title: i18n.t('WOOD'), property: 'woodTypeName' },
      { title: i18n.t('REDUCTION'), property: 'reduction' },
    ],
    [i18n]
  )

  useEffect(() => {
    if (reductionTypesLoaded || reductionTypesFormulasLoaded) return
    console.log('loading dagta...')
    loadData()
  }, [])

  const enhancedReductionTypes = useMemo(() => {
    return enhanceReductionTypes()
  }, [woodTypes, reductionTypes, reductionTypeFormulas])

  const reductionTypesTableData: ReductionType[] = useMemo(() => {
    return TableUtils<ReductionType>().filterData(enhancedReductionTypes, reductionTypeSearchValue)
  }, [enhancedReductionTypes, reductionTypeSearchValue])

  function loadData() {
    setIsLoading(true)
    loadWoodTypes(isAdmin).catch(console.error)
    loadReductionTypesFormula(isAdmin).catch(console.error)
    loadReductionTypes(isAdmin)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  function enhanceReductionTypes(): ReductionType[] {
    if (!reductionTypes || !reductionTypeFormulas || !woodTypes) return []

    console.log('Wood Types:', woodTypes)
    console.log('Reduction Type Formulas:', reductionTypeFormulas)

    return reductionTypes.map(reductionType => {
      const relatedFormulas = reductionTypeFormulas.filter(formula => formula.reductionTypeId === reductionType.reductionTypeId)

      console.log(`Processing Reduction Type: ${reductionType.reductionTypeName}`)
      console.log('Related Formulas:', relatedFormulas)

      const woodReductions = relatedFormulas.map(formula => {
        console.log('Checking formula for woodTypeId:', formula.woodTypeId)

        const woodType = woodTypes.find(wood => wood.woodTypeId === formula.woodTypeId)

        console.log('Found woodType:', woodType)

        return {
          woodTypeId: formula.woodTypeId,
          woodTypeName: woodType ? woodType.woodTypeName : 'Unknown',
          reduction: formula.reduction,
        }
      })

      const enhancedType = {
        ...reductionType,
        woodReductions,
      }

      console.log('Enhanced ReductionType:', enhancedType)

      return enhancedType
    })
  }

  const handleNewReductionTypePress = () => {
    createReductionTypeModal.show({})
  }

  const handleReductionTypeEdit = (reductionType: ReductionType) => {
    const selectedReductionType = reductionTypes.find(c => c.reductionTypeId === reductionType.reductionTypeId)
    createReductionTypeModal.show({ editId: selectedReductionType?.reductionTypeId.toString() })
  }

  const handleReductionTypeDelete = (reductionType: ReductionType) => {
    const selectedReductionType = reductionTypes.find(c => c.reductionTypeId === reductionType.reductionTypeId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteReductionType({ reductionTypeId: Number(selectedReductionType?.reductionTypeId) })
            .then(() => {
              loadData()
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  const onNewReductionTypeCreated = (_reductionType: ReductionType | ReductionTypeFormula | unknown) => {
    createReductionTypeModal.close()
    loadData()
  }

  const reductionTypesTableHeader: TableHeaderData<ReductionType>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'reductionTypeName' },
      { title: i18n.t('CODE'), property: 'reductionTypeCode' },
    ],
    [i18n]
  )

  // const reductionTypesTableData: ReductionType[] = useMemo(() => {
  //   return TableUtils<ReductionType>().filterData(reductionTypes, reductionTypeSearchValue)
  // }, [reductionTypes, reductionTypeSearchValue])

  return (
    <>
      <IM.View spacing="all" style={styles.container}>
        <TableWrapper
          tableTitle={i18n.t('REDUCTION_FORMULAS')}
          tableContainerSpacing={'all'}
          headerData={reductionTypesTableHeader}
          tableData={reductionTypesTableData}
          subTableDataKey="woodReductions"
          subTableHeaderData={formulasTableHeader}
          maxTableHeight={750}
          showNewItemRow={true}
          hasPaging
          rowsPerPage={15}
          onNewItemPress={handleNewReductionTypePress}
          hasEditAndDelete={true}
          onEditPress={handleReductionTypeEdit}
          onDeletePress={handleReductionTypeDelete}
        />
      </IM.View>
      <ReductionTypeCreateModal controller={createReductionTypeModal} onCreateReductionType={onNewReductionTypeCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
})
