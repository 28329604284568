import { IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import { TableHeaderIconData } from '../../types/types'
import Row from './Row'

export type TableHeaderProps<T> = {
  headerData: TableHeaderIconData<T>[]
  style?: StyleProp<ViewStyle>
  widthArray?: number[]
  flexArray?: number[]
  hasEditAndDelete?: boolean
  hasSelection?: boolean
  backgroundHeader?: string
  allItemsSelected?: boolean
  onSelectAll?: () => void
}

export default function TableHeader<T>({
  headerData,
  style,
  flexArray,
  widthArray,
  hasEditAndDelete,
  backgroundHeader,
  hasSelection,
  allItemsSelected,
  onSelectAll,
}: TableHeaderProps<T>) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <Row
      data={headerData as TableHeaderIconData<unknown>[]}
      rowStyle={[IMLayout.shadow, style, { backgroundColor: backgroundHeader ? backgroundHeader : theme.table.header.background }]}
      textStyle={{ color: theme.table.header.text }}
      flexArray={flexArray}
      widthArray={widthArray}
      lastEmptyCell={hasEditAndDelete}
      firstEmptyCell={hasSelection}
      allItemsSelected={allItemsSelected}
      onSelectAll={onSelectAll}
    />
  )
}
