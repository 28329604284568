import { apiOptions } from '../apis/apiOptions'
import { apiUtils } from '../apis/apiUtils'
import { API_BASE_URL } from '../constants/url'
import { ExportLotData } from '../screens/Lots/ExportLotView'
import { PrintLotData } from '../screens/Lots/PrintLotView'
import { ExportFormat, ExportFormatType, exportLanguageValues, LanguageType, Lot, PrintType, PrintTypeSelection } from '../types/types'

const ExportPrintUtils = {
  convertExportFormatToSelector(type: ExportFormat | undefined): ExportFormatType | undefined {
    if (!type) return undefined
    switch (type) {
      case ExportFormat.XLS:
        return { value: ExportFormat.XLS, label: 'XLS' }
      case ExportFormat.CSV:
        return { value: ExportFormat.CSV, label: 'CSV' }
      case ExportFormat.IL_CUBATORE_SHARING:
        return { value: ExportFormat.IL_CUBATORE_SHARING, label: 'IL_CUBATORE_SHARING' }
      case ExportFormat.TIMBER_SHOCK:
        return { value: ExportFormat.TIMBER_SHOCK, label: 'TIMBER_SHOCK' }
    }
  },
  convertPrintTypeToSelector(type: PrintType | undefined): PrintTypeSelection | undefined {
    if (!type) return undefined
    switch (type) {
      case PrintType.DETAIL:
        return { value: PrintType.DETAIL, label: 'DETAIL' }
      case PrintType.PROTOCOL:
        return { value: PrintType.PROTOCOL, label: 'PROTOCOL' }
    }
  },
  convertExportLanguageToSelector(type: string | undefined): LanguageType | undefined {
    if (!type) return undefined
    return exportLanguageValues.find(l => l.value === type)
  },

  //TODO: implement sharing and check the other two formats
  async exportLotData(lots: Lot[], exportData: ExportLotData) {
    switch (exportData.format) {
      case ExportFormat.XLS: {
        const url_xls = `${API_BASE_URL}/${exportData.language}/api/export/xls/trunkslots?diameterMarkup=${exportData.diameter}&${createLotIds(lots)}`

        return downloadBlob(url_xls, 'lots_export.csv')
      }
      default:
        return new Promise((_, reject) => reject('Format not supported'))
    }
  },
  async printLotData(lots: Lot[], printData: PrintLotData) {
    const baseUrl = `${API_BASE_URL}/api/print/`
    switch (printData.type) {
      case PrintType.DETAIL: {
        const url_detail = `${baseUrl}detail?${createLotIds(lots)}`
        return downloadBlob(url_detail, 'DettagliMisurazione.pdf')
      }
      case PrintType.PROTOCOL: {
        const url_protocol = `${baseUrl}protocol?${createLotIds(lots)}`
        return downloadBlob(url_protocol, 'VerbaleMisurazione.pdf')
      }
      default:
        return new Promise((_, reject) => reject('Type not supported'))
    }
  },
}

function createLotIds(lots: Lot[]) {
  return lots
    .sort(l => l.lotId)
    .map(l => `ids=${l.lotId}`)
    .join('&')
}

async function downloadBlob(blobUrl: string, filename: string) {
  try {
    const url = await apiUtils.fetchBlob(blobUrl, apiOptions.token)
    if (!url) return new Promise((_, reject) => reject('No response from server'))

    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.target = '_self'
    document.body.appendChild(a)
    a.click()

    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
    return new Promise(resolve => resolve(true))
  } catch (error) {
    return new Promise((_, reject) => reject(error))
  }
}

export default ExportPrintUtils
