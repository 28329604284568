import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef, useEffect, useImperativeHandle, useState } from 'react'

import { Screen } from '../components/screen/Screen'
import { FormProvider } from '../contexts/FormContext'
import { PackageCreationContextProvider } from '../contexts/PackageCreationContext'
import useEditOrCreateScreenBackManager from '../hooks/useEditOrCreateScreenBackManager'
import PackageCreateHeader from '../screens/PackageCreate/PackageCreateHeader'
import PackageCreateView from '../screens/PackageCreate/PackageCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type PackageCreateModalProps = {
  controller: ModalController<PackageCreateModalControllerData>
  onCreateCallback?: (id: string) => void
  onSaveCallback?: () => void
}

export type PackageCreateModalControllerData = {
  editPackageId?: number
  tmpId?: string
}

export default function PackageCreateModal({ controller, onCreateCallback, onSaveCallback }: PackageCreateModalProps) {
  const ref = createRef<ModalScreenRef>()
  const { i18n } = useLanguage()

  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })
  const isModal = controller !== undefined
  const isEditModePackageId = controller?.data?.editPackageId !== undefined
  const isEditModeTmpId = controller?.data?.tmpId !== undefined

  const editMode = isEditModePackageId || isEditModeTmpId

  const [isShown, setIsShown] = useState(false)

  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const handleCreate = (id: string) => {
    onCreateCallback?.(id)
    controller.close()
  }

  const handleSave = () => {
    onSaveCallback?.()
    controller.close()
  }

  useEffect(() => {
    if (!controller.isShown) setIsShown(false)
  }, [controller.isShown])

  return (
    <BaseServiceModal controller={controller} childRef={ref} onModalShow={() => setIsShown(true)}>
      <PackageCreationContextProvider>
        <Screen>
          <Screen.Header
            goBack={() => handleGoBack()}
            backHandlerCallback={handleGoBack}
            isModal={isModal}
            title={editMode ? i18n.t('EDIT_PACKAGE') : i18n.t('NEW_PACKAGE')}
          />
          <PackageCreateHeader />

          <Screen.Content>
            {isShown && (
              <FormProvider>
                <PackageCreateView
                  editPackageId={controller.data?.editPackageId}
                  tmpId={controller.data?.tmpId}
                  onSave={handleSave}
                  onCreate={handleCreate}
                />
              </FormProvider>
            )}
            {!isShown && <IM.LoadingSpinner isVisible={true} />}
          </Screen.Content>
        </Screen>
      </PackageCreationContextProvider>
    </BaseServiceModal>
  )
}
