import { CardProps, IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { LotType } from '../../types/types'
import CardLeftTitle from '../CardLeftTitle'

export type LotTypeProps = { lotType: LotType } & CardProps

export default function LotTypeCard({ lotType, selected, ...cardProps }: LotTypeProps) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{i18n.t(lotType.label)}</IM.Text>
    </IM.Card>
  )
}
