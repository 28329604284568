import { CardProps, IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { WoodType } from '../../types/types'
import CardLeftTitle from '../CardLeftTitle'

export type WoodTypeCardProps = { woodType: WoodType } & CardProps

export default function WoodTypeCard({ woodType, selected, ...cardProps }: WoodTypeCardProps) {
  const { theme } = useTheme()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.View style={styles.card}>
        <IM.Text>{woodType.woodTypeName}</IM.Text>
      </IM.View>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  card: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
