import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import DateInput from '../../components/DateInput'
import NumberInput from '../../components/NumberInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import QualitySelector from '../../components/selectors/QualitySelector'
import WoodSelector from '../../components/selectors/WoodSelector'
import TextInput from '../../components/TextInput'
import { ThemeColorExpanded } from '../../types'
import { Trunk } from '../../types/types'

export interface TrunkCreateViewProps {
  editId?: number
  onSave?: () => void
  lotId?: number
}

type ValidateTrunkField = 'trunkNumber' | 'qualityId' | 'woodId' | 'addDate' | 'length' | 'diameter1' | 'diameter2' | 'reduction'
type RequiredField = { value: string | number | undefined; field: ValidateTrunkField }

const now = new Date()

const year = now.getFullYear()
const month = (now.getMonth() + 1).toString().padStart(2, '0')
const day = now.getDate().toString().padStart(2, '0')
const formattedDate = `${year}-${month}-${day}`

const TrunkCreateView: React.FC<TrunkCreateViewProps> = ({ editId, onSave, lotId }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [trunk, setTrunk] = useState<Partial<Trunk>>({ addDate: formattedDate })
  const [missingFields, setMissingFields] = useState<ValidateTrunkField[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (editId) {
      loadEditTrunk().catch(console.error)
    }
  }, [editId])

  async function loadEditTrunk() {
    setIsLoading(true)
    await api
      .getTrunk({ trunkId: Number(editId) })
      .then(response => {
        const editTrunk: Trunk = {
          trunkNumber: response.trunkNumber,
          qualityId: response.qualityId,
          woodId: response.woodId,
          addDate: response.addDate,
          lenght: response.lenght,
          diameter1: response.diameter1,
          diameter2: response.diameter2,
          diameter3: response.diameter3,
          diameter4: response.diameter4,
          reduction: response.reduction,
          bonusDiameter: response.bonusDiameter,
          bonusLength: response.bonusLength,
          bonusWood: response.bonusWood,
          latitude: response.latitude,
          longitude: response.longitude,
          lotId: response.lotId,
          trunkId: response.trunkId,
        }
        setTrunk(editTrunk)
      })
      .catch(console.error)
      .then(() => setIsLoading(false))
  }

  function handleChange(value: Partial<Trunk>) {
    setTrunk(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields: RequiredField[] = [
      { value: trunk.trunkNumber, field: 'trunkNumber' },
      { value: trunk.qualityId, field: 'qualityId' },
      { value: trunk.woodId, field: 'woodId' },
      { value: trunk.addDate, field: 'addDate' },
      { value: trunk.lenght, field: 'length' },
      { value: trunk.diameter1, field: 'diameter1' },
    ]
    const notFilledFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.field)
    console.log('notFilledFields', notFilledFields)
    setMissingFields(notFilledFields)
    return notFilledFields.length === 0
  }

  useEffect(() => {
    if (!showMissingFields) return
    validateForm()
  }, [trunk, showMissingFields])

  function handleReductionChange(mod: 'plus' | 'minus') {
    const oldReduction = trunk.reduction ?? 0
    if (mod === 'plus') {
      handleChange({ reduction: oldReduction + 1 })
    }
    if (mod === 'minus') {
      handleChange({ reduction: oldReduction - 1 })
    }
  }

  const handlePress = () => {
    //VALIDATION
    if (!validateForm()) {
      setShowMissingFields(true)
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    setIsLoading(true)
    handleCreate()
      .catch(console.error)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCreate = async () => {
    const trunkBody: Trunk = {
      trunkId: trunk.trunkId ?? 0,
      lotId: lotId ?? trunk.lotId ?? 0,
      trunkNumber: trunk.trunkNumber ?? 0,
      qualityId: trunk.qualityId ?? 0,
      woodId: trunk.woodId ?? 0,
      addDate: trunk.addDate ?? '',
      lenght: trunk.lenght ?? 0,
      diameter1: trunk.diameter1 ?? 0,
      diameter2: trunk.diameter2 ?? 0,
      diameter3: trunk.diameter3 ?? 0,
      diameter4: trunk.diameter4 ?? 0,
      reduction: trunk.reduction ?? 0,
      bonusDiameter: trunk.bonusDiameter ?? 0,
      bonusLength: trunk.bonusLength ?? 0,
      bonusWood: trunk.bonusWood ?? 0,
      latitude: trunk.latitude,
      longitude: trunk.longitude,
    }

    if (editId) {
      return await api
        .putTrunk(trunkBody)
        .then(() => {
          onSave?.()
        })
        .catch(error => {
          console.error('err', error)
          alert(i18n.t('ERROR'), i18n.t('TRUNK_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
    } else {
      return await api
        .postTrunk(trunkBody)
        .then(() => {
          onSave?.()
        })
        .catch(error => {
          console.error('err', error)
          alert(i18n.t('ERROR'), i18n.t('TRUNK_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
    }
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <TextInput
              required
              title={i18n.t('TRUNK_N')}
              placeholder={i18n.t('TRUNK_N')}
              value={String(trunk.trunkNumber ?? '')}
              onChangeText={value => handleChange({ trunkNumber: Number(value) })}
              spacing={'all'}
              error={missingFields.includes('trunkNumber')}
            />
            <QualitySelector
              required
              selectedQualityId={trunk.qualityId}
              editable={true}
              onChange={value => handleChange({ qualityId: value?.qualityId })}
              spacing={['all']}
              error={missingFields.includes('qualityId')}
            />
            <WoodSelector
              required
              selectedWoodId={trunk.woodId}
              editable={true}
              onChange={value => handleChange({ woodId: value?.woodId })}
              spacing={['all']}
              showReduction
              error={missingFields.includes('woodId')}
            />

            <DateInput
              containerStyle={IMLayout.flex.f1}
              title={i18n.t('DATE')}
              value={trunk.addDate}
              editable={false}
              onChangeDate={() => {
                //nothing since it is not editable
              }}
              spacing={['all']}
            />
            <NumberInput
              required
              title={i18n.t('LENGTH')}
              placeholder={i18n.t('LENGTH')}
              value={trunk.lenght}
              onChange={value => handleChange({ lenght: Number(value.nativeEvent.text) })}
              spacing={'all'}
              unit={'cm'}
              disableFastInputs
              error={missingFields.includes('length')}
            />
            <NumberInput
              required
              title={i18n.t('DIAMETER_1')}
              placeholder={i18n.t('DIAMETER_1')}
              value={trunk.diameter1}
              onChange={value => handleChange({ diameter1: Number(value.nativeEvent.text) })}
              spacing={'all'}
              unit={'cm'}
              disableFastInputs
              error={missingFields.includes('diameter1')}
            />
            <NumberInput
              title={i18n.t('DIAMETER_2')}
              placeholder={i18n.t('DIAMETER_2')}
              value={trunk.diameter2}
              onChange={value => handleChange({ diameter2: Number(value.nativeEvent.text) })}
              spacing={'all'}
              unit={'cm'}
              disableFastInputs
            />
            <NumberInput
              title={i18n.t('DIAMETER_3')}
              placeholder={i18n.t('DIAMETER_3')}
              value={trunk.diameter3}
              onChange={value => handleChange({ diameter3: Number(value.nativeEvent.text) })}
              spacing={'all'}
              unit={'cm'}
              disableFastInputs
            />
            <NumberInput
              title={i18n.t('DIAMETER_4')}
              placeholder={i18n.t('DIAMETER_4')}
              value={trunk.diameter4}
              onChange={value => handleChange({ diameter4: Number(value.nativeEvent.text) })}
              spacing={'all'}
              unit={'cm'}
              disableFastInputs
            />
            <NumberInput
              title={i18n.t('REDUCTION')}
              placeholder={i18n.t('REDUCTION')}
              value={trunk.reduction}
              onChange={value => handleChange({ reduction: Number(value.nativeEvent.text) })}
              spacing={'all'}
              error={missingFields.includes('reduction')}
              onPlus={() => handleReductionChange('plus')}
              onMinus={() => handleReductionChange('minus')}
            />
            {/* <TextInput
    required
    title={i18n.t('BONUS_DIAMETER')}
    placeholder={i18n.t('BONUS_DIAMETER')}
    value={String(trunk.bonusDiameter ?? '')}
    onChangeText={value => handleChange({ bonusDiameter: Number(value) })}
    spacing={'all'}
    error={missingFields.includes('bonusDiameter')}
  />
  <TextInput
    required
    title={i18n.t('BONUS_LENGTH')}
    placeholder={i18n.t('BONUS_LENGTH')}
    value={String(trunk.bonusLength ?? '')}
    onChangeText={value => handleChange({ bonusLength: Number(value) })}
    spacing={'all'}
    error={missingFields.includes('bonusLength')}
  />
  <TextInput
    required
    title={i18n.t('BONUS_WOOD')}
    placeholder={i18n.t('BONUS_WOOD')}
    value={String(trunk.bonusWood ?? '')}
    onChangeText={value => handleChange({ bonusWood: Number(value) })}
    spacing={'all'}
    error={missingFields.includes('bonusWood')}
  />
  <TextInput
    title={i18n.t('LATITUDE')}
    placeholder={i18n.t('LATITUDE')}
    value={String(trunk.latitude ?? '')}
    onChangeText={value => handleChange({ latitude: Number(value) })}
    spacing={'all'}
  />
  <TextInput
    title={i18n.t('LONGITUDE')}
    placeholder={i18n.t('LONGITUDE')}
    value={String(trunk.longitude ?? '')}
    onChangeText={value => handleChange({ longitude: Number(value) })}
    spacing={'all'}
  /> */}
          </ScrollViewForm>

          <IM.View style={styles.buttonContainer}>
            <PressableTextIcon
              icon={['fal', 'floppy-disk']}
              alignIcon="right"
              pressableStyle={styles.webButton}
              style={{ color: theme.button.icon }}
              iconColor={theme.button.icon}
              onPress={() => handlePress()}>
              {i18n.t('SAVE')}
            </PressableTextIcon>
          </IM.View>
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default TrunkCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
