import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import PressableIcon from '../../../PressableIcon'
import Cell from './Cell'

export type SelectionCellProps = {
  numItems: number
  isSelected?: boolean
  onSelection?: () => void
  height?: number
  style?: StyleProp<ViewStyle>
}

export default function SelectionCell({ numItems, onSelection, isSelected, height, style }: SelectionCellProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const icon: IconProp = isSelected ? ['fas', 'check-square'] : ['fal', 'square']

  return (
    <Cell key={numItems} width={48} height={height} cellStyle={{ paddingHorizontal: 5 }} cellContainerStyle={style}>
      <PressableIcon icon={icon} color={theme.table.icon} size={16} onPress={onSelection} pressableRatio={0.8} />
    </Cell>
  )
}
