export const apiUtils = {
  fetchBlob: async (url: string, token: string, abortController?: AbortController) => {
    return new Promise<string | null>((resolve, reject) => {
      fetch(url, {
        headers: { Authorization: token },
        signal: abortController?.signal,
      })
        .then(response => {
          if (!response.ok) throw response
          return response.blob()
        })
        .then(blob => {
          if (!blob) {
            resolve(null)
            return
          }
          const reader = new FileReader()
          reader.onloadend = () => {
            if (!reader.result) {
              reject(new Error('No result from reader'))
              return
            }
            if (typeof reader.result === 'string') {
              resolve(reader.result?.toString())
              return
            }
            reject(new Error('Invalid blob result'))
          }
          reader.onerror = reject
          reader.onabort = reject
          reader.readAsDataURL(blob)
        })
        .catch(reject)
    })
  },
}
