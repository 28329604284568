import { CardProps, IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { PackageUm } from '../../types/types'
import CardLeftTitle from '../CardLeftTitle'

export type PackageTypeProps = { packageUm: PackageUm } & CardProps

export default function PackageTypeCard({ packageUm, selected, ...cardProps }: PackageTypeProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  const getLabel = (value: PackageUm['value']): string => {
    switch (value) {
      case 'CMCMMM':
        return i18n.t('CMCMMM')
      case 'MMMMMM':
        return i18n.t('MMMMMM')
      case 'FTININ':
        return i18n.t('FTININ')
      default:
        return ''
    }
  }

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{getLabel(packageUm.value)}</IM.Text>
    </IM.Card>
  )
}
