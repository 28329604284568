import { CardProps, IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { Quality } from '../../apis/apiResponseTypes' // Assuming Quality type is defined
import CardLeftTitle from '../CardLeftTitle'

export type QualityCardProps = { quality: Quality } & CardProps

export default function QualityCard({ quality, selected, ...cardProps }: QualityCardProps) {
  const { theme } = useTheme()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{quality.qualityName}</IM.Text>
    </IM.Card>
  )
}
