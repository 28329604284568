import { CardProps, IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { LicenseSelectType } from '../../types/types'
import CardLeftTitle from '../CardLeftTitle'

export type LicenseTypeProps = { licenseType: LicenseSelectType } & CardProps

export default function LicenseTypeCard({ licenseType, selected, ...cardProps }: LicenseTypeProps) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{i18n.t(licenseType.label)}</IM.Text>
    </IM.Card>
  )
}
