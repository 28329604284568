import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import PressableIcon from '../../../PressableIcon'
import Cell from './Cell'

export type EditDeleteCellProps = {
  numItems: number
  onDeletePress?: () => void
  onEditPress?: () => void
  height?: number
  style?: StyleProp<ViewStyle>
}

export default function EditDeleteCell({ numItems, onDeletePress, onEditPress, height, style }: EditDeleteCellProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <Cell key={numItems} width={80} height={height} cellStyle={{ marginLeft: 10, paddingHorizontal: 5 }} cellContainerStyle={style}>
      <PressableIcon icon={['fal', 'trash']} color={theme.table.icon} size={16} onPress={onDeletePress} pressableRatio={0.8} />
      <PressableIcon icon={['fal', 'pen']} color={theme.table.icon} size={16} onPress={onEditPress} pressableRatio={0.8} />
    </Cell>
  )
}
