import { Language } from '@infominds/react-native-components'

import { ThemeColorExpanded } from '../../../types'
import TimeUtils from '../../../utils/TimeUtils'
import { SortIconData, SortProperty, TableHeaderData } from '../types/types'

const TableUtils = <T>() => ({
  sum: (arr: number[]): number => arr.reduce((acc, n) => acc + n, 0),
  getRowColorFromIndex: (index: number, theme: ThemeColorExpanded): string => (index % 2 === 0 ? theme.table.rowEven : theme.table.rowOdd),
  isLastItem: (index: number, length: number): boolean => index === length - 1,
  getSortIconData: (sortProperty: SortProperty<T>, property: keyof T, theme: ThemeColorExpanded): SortIconData => {
    // sorting is deactivated for the current property
    if (sortProperty.property !== property) {
      return {
        icon: ['fal', 'arrow-down-short-wide'],
        color: theme.table.header.icon.inactive,
        direction: 'asc',
      }
    }
    // sorting is activated for the current property
    else {
      return {
        icon: sortProperty.direction === 'asc' ? ['fas', 'arrow-down-short-wide'] : ['fas', 'arrow-up-short-wide'],
        color: theme.table.header.icon.active,
        direction: sortProperty.direction === 'asc' ? 'desc' : 'asc',
      }
    }
  },
  sortTableData: (tableData: T[], headerData: TableHeaderData<T>[], sortProperty: SortProperty<T> | undefined, language: Language): T[] => {
    const sortedData = [...tableData]

    // if sorting is activated - sort the data
    if (sortProperty) {
      const isDate = headerData.find(data => data.property === sortProperty.property)?.isDate

      // sort the data based on the sort property
      sortedData.sort((a, b) => {
        // get the values of the current property
        let aValue = a[sortProperty.property] as unknown as string | number | Date
        let bValue = b[sortProperty.property] as unknown as string | number | Date

        // if the values are dates, parse them as such
        if (isDate) {
          aValue = TimeUtils.parse(aValue as string, language)
          bValue = TimeUtils.parse(bValue as string, language)
        }
        // else make them case insensitive for comparision
        else if (typeof aValue === 'string' && typeof bValue === 'string') {
          aValue = aValue.toLowerCase()
          bValue = bValue.toLowerCase()
        }

        // compare the values based on the sort direction
        if (aValue < bValue) return sortProperty.direction === 'asc' ? -1 : 1
        if (aValue > bValue) return sortProperty.direction === 'asc' ? 1 : -1
        return 0
      })
    }

    return sortedData
  },
  convertTableDataToStringArray: (tableData: T[], headerData: TableHeaderData<T>[]): string[][] => {
    const headerProperties = headerData.map(data => data.property)
    return tableData.map(row =>
      headerProperties.map(property => {
        if (row[property] === null || row[property] === undefined) return ''
        return String(row[property])
      })
    )
  },
  filterData: (tableData: T[], searchValue: string | undefined): T[] => {
    if (!searchValue) return tableData
    return tableData.filter(data => {
      return Object.values(data as { [key: string]: unknown }).some(value => String(value).toLowerCase().includes(searchValue.toLowerCase()))
    })
  },
  getItemFromIndex: (
    index: number,
    tableData: T[],
    headerData: TableHeaderData<T>[],
    sortProperty: SortProperty<T> | undefined,
    language: Language
  ): T => {
    const sortedData = TableUtils<T>().sortTableData(tableData, headerData, sortProperty, language)
    return sortedData[index]
  },
  calculateVisiblePages: (currentPage: number, totalPages: number, maxVisiblePages: number) => {
    //if ther is only one page, return just that page
    if (totalPages <= 1) {
      return [1]
    }

    //initialize pages array with the first page
    const pages: (number | string)[] = [1]

    // calculate additional visual pages excluding the first and last page
    const numPagesToShow = maxVisiblePages - 2
    // calculate the half of the pages to show
    const half = Math.floor(numPagesToShow / 2)

    // if there are less pages than the maximum visible pages, show all pages without dots
    if (totalPages <= maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1)
    }

    // set the start and end page of the dynamic pages (excluding the first and last page)
    let startPage = Math.max(2, currentPage - half)
    let endPage = Math.min(totalPages - 1, currentPage + half)

    // if the range is smaller than the maximum visible pages, shift the range to fill the maximum visible pages (to always show the same amount of pages (not counting the dots))
    if (endPage - startPage + 1 < numPagesToShow) {
      // if the start page is at the beginning, shift the end page to fill the range
      if (startPage === 2) {
        endPage = Math.min(startPage + numPagesToShow - 1, totalPages - 1)
      }
      // if the end page is at the end, shift the start page to fill the range
      else if (endPage === totalPages - 1) {
        startPage = Math.max(totalPages - numPagesToShow, 2)
      }
    }

    // add left dots if needed
    if (startPage > 2) {
      pages.push('...')
    }

    // add the pages around the current page
    pages.push(...Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i))

    // add right dots if needed
    if (endPage < totalPages - 1) {
      pages.push('...')
    }

    // always show the last page
    pages.push(totalPages)

    return pages
  },
  formatStringWithSign: (value: string | number, sign: string): string => {
    if (value === null || value === undefined) return ''
    return `${value} ${sign}`
  },
})

export default TableUtils
