import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { exportLanguageValues, LanguageType, ListSection } from '../../types/types'
import ExportLanguageCard from '../cards/ExportLanguageCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: LanguageType | undefined) => void
  required?: boolean
  selectedExportLanguage?: LanguageType
}

export default function ExportLanguageSelector({ onChange, selectedExportLanguage, ...props }: Props) {
  const { i18n, language } = useLanguage()

  const [data] = useState<LanguageType[]>(exportLanguageValues)

  const render = ({ item }: SectionListRenderItemInfo<LanguageType, ListSection<LanguageType>>, onPress?: () => void) => {
    return <ExportLanguageCard exportLanguage={item} onPress={onPress} selected={item.value === selectedExportLanguage?.value} spacing={['all']} />
  }

  const handleOnChange = (newValue: LanguageType | undefined) => {
    onChange(newValue)
  }

  return (
    <SelectInput
      id={'value'}
      data={data}
      value={selectedExportLanguage}
      loading={false}
      refresh={undefined}
      title={i18n.t('LANGUAGE')}
      screenTitle={i18n.t('LANGUAGE')}
      noDataMessage={i18n.t('NO_FORMAT')}
      required
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => i18n.t(item.label)}
      deferredTimeout={0}
      disableLoadAfterMount
      autoSelectIfUnique
      {...props}
    />
  )
}
