import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { ExportFormatType, exportFormatValues, ListSection } from '../../types/types'
import ExportFormatCard from '../cards/ExportFormatCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: ExportFormatType | undefined) => void
  required?: boolean
  selectedExportFormat?: ExportFormatType
}

export default function ExportFormatSelector({ onChange, selectedExportFormat, ...props }: Props) {
  const { i18n } = useLanguage()

  const [data] = useState<ExportFormatType[]>(exportFormatValues)

  const render = ({ item }: SectionListRenderItemInfo<ExportFormatType, ListSection<ExportFormatType>>, onPress?: () => void) => {
    return <ExportFormatCard exportFormat={item} onPress={onPress} selected={item.value === selectedExportFormat?.value} spacing={['all']} />
  }

  const handleOnChange = (newValue: ExportFormatType | undefined) => {
    onChange(newValue)
  }

  return (
    <SelectInput
      id={'value'}
      data={data}
      value={selectedExportFormat}
      loading={false}
      refresh={undefined}
      title={i18n.t('FORMAT')}
      screenTitle={i18n.t('FORMAT')}
      noDataMessage={i18n.t('NO_FORMAT')}
      required
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => i18n.t(item.label)}
      deferredTimeout={0}
      disableLoadAfterMount
      autoSelectIfUnique
      {...props}
    />
  )
}
