import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, LayoutChangeEvent, StyleSheet, type StyleProp, type ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../types'
import SkipPageCard from './components/paging/SkipPageCard'
import EmptyRow from './components/rows/EmptyRow'
import NewItemRow from './components/rows/NewItemRow'
import Row from './components/rows/Row'
import TableHeader from './components/rows/TableHeader'
import { Paging, SortProperty, TableHeaderData, TableHeaderIconData } from './types/types'
import TableUtils from './utils/TableUtils'

interface TableProps<T, S> {
  headerData: TableHeaderData<T>[]
  tableData: T[]
  subTableHeaderData?: TableHeaderData<S>[]
  subTableDataKey?: keyof T
  hasEditAndDelete?: boolean
  onDeletePress?: (item: T) => void
  onEditPress?: (item: T) => void
  showEmptyRow?: boolean
  showNewItemRow?: boolean
  onNewItemPress?: () => void
  hasSelection?: boolean
  selectedItems?: T[]
  onSelectionPress?: (item: T) => void
  onSelectAllPress?: (items: T[]) => void
  onDeleteAllSelectionPress?: () => void
  maxHeight?: number
  style?: StyleProp<ViewStyle>
  widthArray?: number[]
  flexArray?: number[]
  paging?: Paging
  backgroundHeader?: string
  subtableFlexArray?: number[]
  newItemRowColor?: string
  onSubtableEditPress?: (item: S) => void
  onSubtableDeletePress?: (item: S) => void
  onSubtableAddPress?: (parentItem: T) => void
}

export default function Table<T, S>({
  headerData,
  tableData,
  subTableHeaderData,
  subTableDataKey,
  style,
  maxHeight,
  showEmptyRow = true,
  flexArray,
  widthArray,
  showNewItemRow,
  onNewItemPress,
  hasEditAndDelete,
  onDeletePress,
  onEditPress,
  hasSelection,
  selectedItems,
  onSelectionPress,
  onSelectAllPress,
  onDeleteAllSelectionPress,
  paging,
  backgroundHeader,
  subtableFlexArray,
  newItemRowColor,
  onSubtableAddPress,
  onSubtableEditPress,
  onSubtableDeletePress,
}: TableProps<T, S>) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { language } = useLanguage()
  const [newItemRowHeight, setNewItemRowHeight] = useState(0)
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())

  const [subTablePages, setSubTablePages] = useState<Record<number, number>>({})
  const maxSubTableRows = 20

  const flatListRef = useRef<FlatList>(null)

  function setSubTablePage(index: number, page: number) {
    setSubTablePages(prev => ({ ...prev, [index]: page }))
  }

  //get the height of the new item row
  const handleNewItemRowLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout
    setNewItemRowHeight(height)
  }

  //calculate the max height of the scrollview
  const scrollViewMaxHeight = showNewItemRow && maxHeight ? maxHeight - newItemRowHeight : maxHeight

  const tableWidth = widthArray ? TableUtils().sum(widthArray) : '100%'

  const [sortProperty, setSortProperty] = useState<SortProperty<T>>({
    property: headerData[0].property,
    direction: 'asc',
  })

  const allPageData: T[] = useMemo(() => {
    let newData = TableUtils<T>().sortTableData(tableData, headerData, sortProperty, language)

    if (paging) {
      newData = newData.slice(paging.skip, paging.take)
    }

    return newData
  }, [tableData, headerData, sortProperty, language, paging])

  const data: string[][] = useMemo(() => {
    return TableUtils<T>().convertTableDataToStringArray(allPageData, headerData)
  }, [headerData, sortProperty, paging, allPageData])

  const areAllItemsSelected = useMemo(() => {
    if (allPageData.length === 0 || !hasSelection || !selectedItems) return false
    return allPageData.every(item => selectedItems?.includes(item))
  }, [hasSelection, selectedItems, allPageData])

  useEffect(() => {
    if (!paging || !flatListRef.current) return

    //scroll to the top when the page changes
    flatListRef.current.scrollToOffset({ offset: 0, animated: true })
  }, [paging])

  const header: TableHeaderIconData<T>[] = useMemo(() => {
    const newHeaderData: TableHeaderIconData<T>[] = [...headerData]
    return newHeaderData.map(item => {
      const sortIconData = TableUtils<T>().getSortIconData(sortProperty, item.property, theme)
      return {
        ...item,
        rightIcon: sortIconData.icon,
        rightIconPress: () => setSortProperty({ property: item.property, direction: sortIconData.direction }),
        rightIconColor: sortIconData.color,
      }
    })
  }, [headerData, sortProperty])

  function onEdit(idx: number) {
    if (!onEditPress) return
    const item: T = TableUtils<T>().getItemFromIndex(idx, allPageData, headerData, sortProperty, language)
    onEditPress(item)
  }

  function onDelete(idx: number) {
    if (!onDeletePress) return
    const item: T = TableUtils<T>().getItemFromIndex(idx, allPageData, headerData, sortProperty, language)
    onDeletePress(item)
  }

  function onSelection(idx: number) {
    if (!onSelectionPress) return
    const item: T = TableUtils<T>().getItemFromIndex(idx, allPageData, headerData, sortProperty, language)
    onSelectionPress(item)
  }

  function onSelectAll() {
    onSelectAllPress?.(allPageData)
  }

  function onDeleteAll() {
    onDeleteAllSelectionPress?.()
  }

  function toggleRowExpansion(index: number) {
    //const mainRowItem: T = TableUtils<T>().getItemFromIndex(index, allPageData, headerData, sortProperty, language)
    //console.debug('mainRow', mainRowItem)
    if (!subTableDataKey) return
    setExpandedRows(prev => {
      const newSet = new Set(prev)
      if (newSet.has(index)) {
        newSet.delete(index)
      } else {
        newSet.add(index)
      }
      return newSet
    })
  }

  function renderItem({ item, index }: { item: string[]; index: number }) {
    const mainRowItem: T = TableUtils<T>().getItemFromIndex(index, allPageData, headerData, sortProperty, language)
    const isExpanded = expandedRows.has(index)

    const subTableData = subTableDataKey && Array.isArray(mainRowItem[subTableDataKey]) ? (mainRowItem[subTableDataKey] as S[]) : []
    const subTablePage = subTablePages[index] || 0
    const paginatedSubTableData = subTableData.slice(subTablePage * maxSubTableRows, (subTablePage + 1) * maxSubTableRows)

    const isSelected = selectedItems ? selectedItems.includes(mainRowItem) : false

    return (
      <>
        <Row
          key={index}
          data={item}
          widthArray={widthArray}
          selectable
          flexArray={flexArray}
          rowStyle={[{ backgroundColor: TableUtils().getRowColorFromIndex(index, theme) }, styles.rows]}
          textStyle={{ color: theme.table.text }}
          onPress={() => toggleRowExpansion(index)}
          onDeletePress={() => {
            onDelete(index)
          }}
          onEditPress={() => {
            onEdit(index)
          }}
          hasEditAndDelete={hasEditAndDelete}
          hasSelection={hasSelection}
          isSelected={isSelected}
          onSelection={() => {
            onSelection(index)
          }}
        />
        {isExpanded && subTableHeaderData && subTableDataKey && Array.isArray(mainRowItem[subTableDataKey]) && (
          <>
            <Table
              headerData={subTableHeaderData}
              tableData={paginatedSubTableData}
              showEmptyRow={false}
              showNewItemRow={true}
              widthArray={widthArray}
              flexArray={subtableFlexArray}
              backgroundHeader={theme.primary}
              hasEditAndDelete={hasEditAndDelete}
              newItemRowColor={theme.primary}
              onEditPress={onSubtableEditPress}
              onDeletePress={onSubtableDeletePress}
              onNewItemPress={() => onSubtableAddPress?.(mainRowItem)}
            />
            <IM.View style={styles.paginationContainer}>
              <SkipPageCard
                direction="prev"
                currentPage={subTablePage + 1}
                handlePageChange={page => setSubTablePage(index, page - 1)}
                totalPages={Math.ceil(subTableData.length / maxSubTableRows)}
              />
              <SkipPageCard
                direction="next"
                currentPage={subTablePage + 1}
                handlePageChange={page => setSubTablePage(index, page - 1)}
                totalPages={Math.ceil(subTableData.length / maxSubTableRows)}
              />
            </IM.View>
          </>
        )}
      </>
    )
  }

  return (
    <IM.View style={[IMLayout.shadow, styles.container, style, { width: tableWidth, maxHeight: maxHeight }]}>
      <FlatList
        ref={flatListRef}
        style={[{ maxHeight: scrollViewMaxHeight }]}
        data={data}
        renderItem={renderItem}
        ListHeaderComponent={
          <TableHeader
            headerData={header}
            style={styles.rows}
            flexArray={flexArray}
            widthArray={widthArray}
            hasEditAndDelete={hasEditAndDelete}
            hasSelection={hasSelection}
            backgroundHeader={backgroundHeader}
            allItemsSelected={areAllItemsSelected}
            onSelectAll={onSelectAll}
          />
        }
        stickyHeaderIndices={[0]}
        ListEmptyComponent={showEmptyRow && data.length === 0 ? <EmptyRow /> : null}
        initialScrollIndex={0}
      />
      {((showNewItemRow && onNewItemPress) || (hasSelection && onDeleteAllSelectionPress)) && (
        <NewItemRow
          backgroundColor={newItemRowColor}
          iconColor={newItemRowColor ? theme.button.background : undefined}
          onNewItemPress={onNewItemPress}
          onLayout={handleNewItemRowLayout}
          onDeleteAllPress={onDeleteAll}
          deleteIconVisible={hasSelection && onDeleteAllSelectionPress && selectedItems && selectedItems.length > 0}
        />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: IMLayout.borderRadius,
    overflow: 'hidden',
  },
  rows: {
    paddingHorizontal: 10,
  },
  paginationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    alignItems: 'center',
  },
  paginationButton: {
    padding: 8,
    borderRadius: 4,
  },
})
