import { CardProps, IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { PackageType } from '../../types/types'
import CardLeftTitle from '../CardLeftTitle'

export type PackageTypeProps = { packageType: PackageType } & CardProps

export default function PackageTypeCard({ packageType, selected, ...cardProps }: PackageTypeProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{i18n.t(packageType.label)}</IM.Text>
    </IM.Card>
  )
}
