import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import { BaseTextInput } from '../../components/baseTextInput/BaseTextInput'
import GroupSpacer from '../../components/GroupSpacer'
import NumberInput from '../../components/NumberInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import ExportFormatSelector from '../../components/selectors/ExportFormatSelector'
import ExportLanguageSelector from '../../components/selectors/ExportLanguageSelector'
import TextInput from '../../components/TextInput'
import { ThemeColorExpanded } from '../../types'
import { ExportFormat, exportLanguageValues, Lot } from '../../types/types'
import ExportPrintUtils from '../../utils/exportPrintUtils'

export interface ExportLotViewProps {
  lots?: Lot[]
  onExport?: () => void
}

export interface ExportLotData {
  format: ExportFormat
  language: string
  email?: string
  diameter: number
}

const ExportLotView: React.FC<ExportLotViewProps> = ({ onExport, lots }) => {
  const { i18n, language } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [exportData, setExportData] = useState<Partial<ExportLotData>>({
    format: ExportFormat.XLS,
    diameter: 0,
    language: i18n.t('ID'),
  })
  const [showMissingEmail, setShowMissingEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const currentLanguage = useMemo(() => exportLanguageValues.find(l => l.value === language), [language])

  function handleChange(value: Partial<ExportLotData>) {
    setExportData(prev => ({ ...prev, ...value }))
  }

  function handleDiameterChange(mod: 'plus' | 'minus') {
    const oldDiameter = exportData.diameter ?? 0
    if (mod === 'plus') {
      handleChange({ diameter: oldDiameter + 1 })
    }
    if (mod === 'minus') {
      if (oldDiameter === 0) return
      handleChange({ diameter: oldDiameter - 1 })
    }
  }

  function isValid() {
    if (lots === undefined) return false
    if (!exportData.format || !exportData.language) return false
    if (exportData.format === ExportFormat.IL_CUBATORE_SHARING && !exportData.email) {
      setShowMissingEmail(true)
      return false
    }

    return true
  }

  function handleExportPress() {
    // validate
    setShowMissingEmail(false)
    if (!isValid()) return

    // create the exportData
    const finalExportData: ExportLotData = {
      format: exportData.format ?? ExportFormat.XLS,
      language: exportData.language ?? i18n.t('ID'),
      email: exportData.email,
      diameter: exportData.diameter ?? 0,
    }

    setIsLoading(true)
    ExportPrintUtils.exportLotData(lots ?? [], finalExportData)
      .then(() => {
        onExport?.()
      })
      .catch(error => {
        console.error('Error exporting:', error)
        alert(i18n.t('ERROR'), i18n.t('EXPORT_ERROR'), [
          {
            text: i18n.t('OK'),
            style: 'default',
            onPress: () => {
              return
            },
          },
        ])
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <IM.View spacing={'horizontal'}>
              <BaseTextInput.Title title={i18n.t('SETTINGS')} />
            </IM.View>

            <ExportFormatSelector
              editable
              spacing={'all'}
              onChange={value => handleChange({ format: value?.value })}
              selectedExportFormat={ExportPrintUtils.convertExportFormatToSelector(exportData.format)}
            />

            {exportData.format === ExportFormat.IL_CUBATORE_SHARING && (
              <TextInput
                required
                title={i18n.t('EMAIL')}
                placeholder={i18n.t('EMAIL')}
                value={exportData.email ?? ''}
                onChangeText={value => handleChange({ email: value })}
                spacing={'all'}
                error={showMissingEmail}
              />
            )}

            {exportData.format !== ExportFormat.IL_CUBATORE_SHARING && (
              <ExportLanguageSelector
                editable
                spacing={'all'}
                onChange={value => handleChange({ language: value?.value })}
                selectedExportLanguage={ExportPrintUtils.convertExportLanguageToSelector(exportData.language) ?? currentLanguage}
              />
            )}

            <GroupSpacer />
            <IM.View spacing={'horizontal'}>
              <BaseTextInput.Title title={i18n.t('BONUS')} />
            </IM.View>
            <NumberInput
              value={exportData.diameter}
              onChange={value => {
                handleChange({ diameter: Number(value.nativeEvent.text) })
              }}
              onPlus={() => handleDiameterChange('plus')}
              onMinus={() => handleDiameterChange('minus')}
              placeholder={i18n.t('DIAMETER')}
              title={i18n.t('DIAMETER')}
              spacing={'all'}
              unit="%"
            />
          </ScrollViewForm>

          <IM.View style={styles.buttonContainer}>
            <PressableTextIcon
              icon={['fal', 'up-from-bracket']}
              alignIcon="right"
              pressableStyle={styles.webButton}
              style={{ color: theme.button.icon }}
              iconColor={theme.button.icon}
              onPress={() => handleExportPress()}>
              {i18n.t('EXPORT')}
            </PressableTextIcon>
          </IM.View>
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default ExportLotView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
