import { CardProps, IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { Customer } from '../../apis/apiResponseTypes'
import CardLeftTitle from '../CardLeftTitle'

export type CustomerCardProps = { customer: Customer } & CardProps

export default function CustomerCard({ customer, selected, ...cardProps }: CustomerCardProps) {
  const { theme } = useTheme()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{customer.customerName}</IM.Text>
    </IM.Card>
  )
}
