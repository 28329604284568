import { useAlert, useDidUpdate, useLanguage } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'

import api from '../apis/apiCalls'
import {
  Customer,
  Grade,
  Measurer,
  Organization,
  OrganizationLicense,
  Origin,
  Quality,
  Sawmill,
  StandingUp,
  Typology,
  User,
  Wood,
} from '../apis/apiResponseTypes'
import { STORAGE_KEYS } from '../constants/keys'
import { BluetoothDevice, ConfiguredDevice } from '../types'
import { Lot, Package, ReductionType, ReductionTypeFormula, Statistics, WoodType } from '../types/types'

export type DataType =
  | 'organization'
  | 'customers'
  | 'typologies'
  | 'woods'
  | 'grades'
  | 'qualities'
  | 'origins'
  | 'sawmills'
  | 'measurers'
  | 'devices'
  | 'standingUp'
  | 'licenses'
  | 'users'
  | 'organizations'

export interface FetchOptions {
  organizationId: number
  isAdmin?: boolean
}

type DataContextType = {
  dataOk: boolean
  allOrganizations: Organization[]
  setAllOrganizations: React.Dispatch<React.SetStateAction<Organization[]>>
  getOrganizationById: (id: number) => Organization | undefined
  currentOrganization: Organization | undefined
  users: User[]
  setUsers: React.Dispatch<React.SetStateAction<User[]>>
  getUserById: (id: number) => User | undefined
  customers: Customer[]
  setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>
  getCustomerById: (id: number) => Customer | undefined
  woods: Wood[]
  setWoods: React.Dispatch<React.SetStateAction<Wood[]>>
  getWoodById: (id: number) => Wood | undefined
  typologies: Typology[]
  setTypologies: React.Dispatch<React.SetStateAction<Typology[]>>
  getTypologyById: (id: number) => Typology | undefined
  grades: Grade[]
  setGrades: React.Dispatch<React.SetStateAction<Grade[]>>
  getGradeById: (id: number) => Grade | undefined
  qualities: Quality[]
  setQualities: React.Dispatch<React.SetStateAction<Quality[]>>
  getQualityById: (id: number) => Quality | undefined
  origins: Origin[]
  setOrigins: React.Dispatch<React.SetStateAction<Origin[]>>
  getOriginById: (id: number) => Origin | undefined
  sawmills: Sawmill[]
  setSawmills: React.Dispatch<React.SetStateAction<Sawmill[]>>
  getSawmillById: (id: number) => Sawmill | undefined
  measurers: Measurer[]
  setMeasurers: React.Dispatch<React.SetStateAction<Measurer[]>>
  getMeasurerById: (id: number) => Measurer | undefined
  licenses: OrganizationLicense[]
  setLicenses: React.Dispatch<React.SetStateAction<OrganizationLicense[]>>
  getLicenseById: (id: number) => OrganizationLicense | undefined
  fetchData: (options: FetchOptions) => Promise<void>
  fetchSpecificData: (dataTypes: DataType[], options: FetchOptions) => Promise<void>
  packages: Package[]
  setPackages: React.Dispatch<React.SetStateAction<Package[]>>
  lots: Lot[]
  setLots: React.Dispatch<React.SetStateAction<Lot[]>>
  configuredDevices: ConfiguredDevice[]
  connectedDevices: BluetoothDevice[]
  setConnectedDevices: React.Dispatch<React.SetStateAction<BluetoothDevice[]>>
  defaultMenu: string
  setDefaultMenu: React.Dispatch<React.SetStateAction<string>>
  organizationId: number | undefined
  setOrganizationId: React.Dispatch<React.SetStateAction<number | undefined>>
  standingUp: StandingUp[]
  setCurrentOrganization: React.Dispatch<React.SetStateAction<Organization | undefined>>
  license: string | undefined
  setLicense: React.Dispatch<React.SetStateAction<string | undefined>>
  packagesStatistics: Statistics[]
  lotsStatistics: Statistics[]
  statisticsLoaded: boolean
  loadStatistics: (isAdmin: boolean) => Promise<void>
  woodTypes: WoodType[]
  reductionTypes: ReductionType[]
  reductionTypeFormulas: ReductionTypeFormula[]
  loadWoodTypes: (isAdmin: boolean) => Promise<void>
  loadReductionTypes: (isAdmin: boolean) => Promise<void>
  loadReductionTypesFormula: (isAdmin: boolean) => Promise<void>
  woodTypesLoaded: boolean
  reductionTypesLoaded: boolean
  reductionTypesFormulasLoaded: boolean
  setWoodTypes: React.Dispatch<React.SetStateAction<WoodType[]>>
}

export const DataContext = React.createContext<DataContextType | null>(null)

export function DataContextProvider({ children }: PropsWithChildren) {
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([])
  const [currentOrganization, setCurrentOrganization] = useState<Organization | undefined>(undefined)
  const [users, setUsers] = useState<User[]>([])
  const [customers, setCustomers] = useState<Customer[]>([])
  const [woods, setWoods] = useState<Wood[]>([])
  const [typologies, setTypologies] = useState<Typology[]>([])
  const [grades, setGrades] = useState<Grade[]>([])
  const [qualities, setQualities] = useState<Quality[]>([])
  const [origins, setOrigins] = useState<Origin[]>([])
  const [sawmills, setSawmills] = useState<Sawmill[]>([])
  const [measurers, setMeasurers] = useState<Measurer[]>([])
  const [standingUp, setStandingUp] = useState<StandingUp[]>([])
  const [packages, setPackages] = useState<Package[]>([])
  const [licenses, setLicenses] = useState<OrganizationLicense[]>([])
  const [lots, setLots] = useState<Lot[]>([])
  const [dataOk, setDataOk] = useState(false)
  const [organizationId, setOrganizationId] = useState<number | undefined>(undefined)
  const [license, setLicense] = useState<string | undefined>(undefined)
  const [packagesStatistics, setPackagesStatistics] = useState<Statistics[]>([])
  const [lotsStatistics, setLotsStatistics] = useState<Statistics[]>([])
  const [statisticsLoaded, setStatisticsLoaded] = useState(false)
  const [woodTypes, setWoodTypes] = useState<WoodType[]>([])
  const [reductionTypes, setReductionTypes] = useState<ReductionType[]>([])
  const [reductionTypeFormulas, setReductionTypeFormulas] = useState<ReductionTypeFormula[]>([])
  const [reductionTypesFormulasLoaded, setReductionTypesFormulasLoaded] = useState(false)
  const [woodTypesLoaded, setWoodTypesLoaded] = useState(false)
  const [reductionTypesLoaded, setReductionTypesLoaded] = useState(false)

  const [configuredDevices, setConfiguredDevices] = useState<ConfiguredDevice[]>([])
  const [connectedDevices, setConnectedDevices] = useState<BluetoothDevice[]>([])
  const [defaultMenu, setDefaultMenu] = useState<string>('')

  useDidUpdate(() => {
    AsyncStorage.setItem(STORAGE_KEYS.PACKAGES_STORAGE_KEY, JSON.stringify(packages)).catch(console.error)
  }, [packages])

  useDidUpdate(() => {
    AsyncStorage.setItem(STORAGE_KEYS.LOTS_STORAGE_KEY, JSON.stringify(lots)).catch(console.error)
  }, [lots])

  useDidUpdate(() => {
    AsyncStorage.setItem(STORAGE_KEYS.CONNECTED_DEVICES_STORAGE_KEY, JSON.stringify(connectedDevices)).catch(console.error)
  }, [connectedDevices])

  const { alert } = useAlert()
  const { i18n } = useLanguage()

  const showAlert = (message: string) => {
    alert(i18n.t('ERROR'), message, [
      {
        onPress: () => {
          return
        },
        text: i18n.t('OK'),
      },
    ])
  }

  const fetchData = async (options: FetchOptions) => {
    try {
      const [
        organizationData,
        customersData,
        typologiesData,
        woodsData,
        gradesData,
        qualitiesData,
        originsData,
        sawmillsData,
        measurersData,
        devices,
        standingUpData,
        licensesData,
        usersData,
      ] = await Promise.all([
        api.getCurrentOrganization(options),
        api.getCustomers(options),
        api.getTypologies(options),
        api.getWoods(options),
        api.getGrades(options),
        api.getQualities(options),
        api.getOrigins(options),
        api.getSawmills(options),
        api.getMeasurers(options),
        api.getConfiguredDevices(),
        api.getStandingUp(options),
        api.getLicenses(options),
        api.getUsers(options),
      ])

      setCurrentOrganization(organizationData)
      setCustomers(customersData)
      setTypologies(typologiesData)
      setWoods(woodsData)
      setGrades(gradesData)
      setQualities(qualitiesData)
      setOrigins(originsData)
      setSawmills(sawmillsData)
      setMeasurers(measurersData)
      setConfiguredDevices(devices)
      setStandingUp(standingUpData)
      setLicenses(licensesData)
      setUsers(usersData)

      if (options.isAdmin) {
        const organizationsData = await api.getOrganizations()
        setAllOrganizations(organizationsData)
      }
    } catch (error) {
      console.log('Error fetching data', error)
      showAlert('Error fetching data')
    }
  }

  const fetchSpecificData = async (dataTypes: DataType[], options: FetchOptions): Promise<void> => {
    if (dataTypes.length === 0) return
    try {
      await Promise.all(
        dataTypes.map(async dataType => {
          switch (dataType) {
            case 'organization': {
              const organizationData = await api.getCurrentOrganization(options)
              setCurrentOrganization(organizationData)
              break
            }
            case 'customers': {
              const customersData = await api.getCustomers(options)
              setCustomers(customersData)
              break
            }
            case 'typologies': {
              const typologiesData = await api.getTypologies(options)
              setTypologies(typologiesData)
              break
            }
            case 'woods': {
              const woodsData = await api.getWoods(options)
              setWoods(woodsData)
              break
            }
            case 'grades': {
              const gradesData = await api.getGrades(options)
              setGrades(gradesData)
              break
            }
            case 'qualities': {
              const qualitiesData = await api.getQualities(options)
              setQualities(qualitiesData)
              break
            }
            case 'origins': {
              const originsData = await api.getOrigins(options)
              setOrigins(originsData)
              break
            }
            case 'sawmills': {
              const sawmillsData = await api.getSawmills(options)
              setSawmills(sawmillsData)
              break
            }
            case 'measurers': {
              const measurersData = await api.getMeasurers(options)
              setMeasurers(measurersData)
              break
            }
            case 'devices': {
              const devices = await api.getConfiguredDevices()
              setConfiguredDevices(devices)
              break
            }
            case 'standingUp': {
              const standingUpData = await api.getStandingUp(options)
              setStandingUp(standingUpData)
              break
            }
            case 'licenses': {
              const licensesData = await api.getLicenses(options)
              setLicenses(licensesData)
              break
            }
            case 'users': {
              const usersData = await api.getUsers(options)
              setUsers(usersData)
              break
            }
            case 'organizations': {
              if (!options.isAdmin) throw new Error('Not an admin')
              const organizationsData = await api.getOrganizations()
              setAllOrganizations(organizationsData)
              break
            }
            default:
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              throw new Error(`Invalid data type: ${dataType}`)
          }
        })
      )
    } catch (error) {
      console.error(`Error fetching ${dataTypes.toString()}`, error)
      showAlert('Error fetching data')
    }
  }

  const loadStatistics = async (isAdmin: boolean): Promise<void> => {
    if (!isAdmin) return
    try {
      const [packagesStats, lotsStats] = await Promise.all([api.getPackagesStatistics({}), api.getLotsStatistics({})])

      setPackagesStatistics(packagesStats)
      setLotsStatistics(lotsStats)
      setStatisticsLoaded(true)
    } catch (error) {
      console.error('Error loading statistics', error)
      showAlert('Error loading statistics')
    }
  }

  const loadWoodTypes = async (isAdmin: boolean): Promise<void> => {
    if (!isAdmin || !currentOrganization) return
    try {
      const woodTypesResponse = await api.getWoodTypes({ organizationId: currentOrganization?.organizationId })

      setWoodTypes(woodTypesResponse)
      setWoodTypesLoaded(true)
    } catch (error) {
      console.error('Error loading wood types', error)
      showAlert('Error loading wood types')
    }
  }

  const loadReductionTypes = async (isAdmin: boolean): Promise<void> => {
    if (!isAdmin || !currentOrganization) return
    try {
      const reductionTypesResponse = await api.getReductionTypes({ organizationId: currentOrganization?.organizationId })

      setReductionTypes(reductionTypesResponse)
      setReductionTypesLoaded(true)
    } catch (error) {
      console.error('Error loading reduction types', error)
      showAlert('Error loading reduction types')
    }
  }

  const loadReductionTypesFormula = async (isAdmin: boolean): Promise<void> => {
    if (!isAdmin || !currentOrganization) return
    try {
      const reductionTypesResponse = await api.getReductionTypeFormulas({ organizationId: currentOrganization?.organizationId })

      setReductionTypeFormulas(reductionTypesResponse)
      setReductionTypesFormulasLoaded(true)
    } catch (error) {
      console.error('Error loading reduction types', error)
      showAlert('Error loading reduction types')
    }
  }

  const getOrganizationById = (id: number) => allOrganizations.find(organization => organization.organizationId === id)
  const getCustomerById = (id: number) => customers.find(customer => customer.customerId === id)
  const getWoodById = (id: number) => woods.find(wood => wood.woodId === id)
  const getTypologyById = (id: number) => typologies.find(typology => typology.typologyId === id)
  const getGradeById = (id: number) => grades.find(grade => grade.gradeId === id)
  const getQualityById = (id: number) => qualities.find(quality => quality.qualityId === id)
  const getOriginById = (id: number) => origins.find(origin => origin.originId === id)
  const getSawmillById = (id: number) => sawmills.find(sawmill => sawmill.sawmillId === id)
  const getMeasurerById = (id: number) => measurers.find(measurer => measurer.measurerId === id)
  const getLicenseById = (id: number) => licenses.find(lic => lic.licenseId === id)
  const getUserById = (id: number) => users.find(user => user.userId === id)

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const storedPackages = await AsyncStorage.getItem(STORAGE_KEYS.PACKAGES_STORAGE_KEY)
        if (storedPackages) {
          setPackages(JSON.parse(storedPackages) as Package[])
        } else {
          setPackages([])
        }
      } catch (error) {
        console.error('Failed to load packages from AsyncStorage', error)
      }
    }

    const fetchLots = async () => {
      try {
        const storedLots = await AsyncStorage.getItem(STORAGE_KEYS.LOTS_STORAGE_KEY)
        if (storedLots) {
          setLots(JSON.parse(storedLots) as Lot[])
        } else {
          setLots([])
        }
      } catch (error) {
        console.error('Failed to load lots from AsyncStorage', error)
      }
    }

    const fetchConnectedDevices = async () => {
      try {
        const storedDevices = await AsyncStorage.getItem(STORAGE_KEYS.CONNECTED_DEVICES_STORAGE_KEY)
        if (storedDevices) {
          setConnectedDevices(JSON.parse(storedDevices) as BluetoothDevice[])
        } else {
          setConnectedDevices([])
        }
        setDataOk(true)
      } catch (error) {
        console.error('Failed to load connected devices from AsyncStorage', error)
      }
    }

    const fetchDefaultMenu = async () => {
      try {
        const storedDefaultMenu = await AsyncStorage.getItem(STORAGE_KEYS.DEFAULT_MENU)
        if (storedDefaultMenu) {
          setDefaultMenu(storedDefaultMenu)
        } else {
          setDefaultMenu('')
        }
      } catch (error) {
        console.error('Failed to load default menu from AsyncStorage', error)
      }
    }

    fetchDefaultMenu().catch(error => {
      console.error('Failed to fetch default menu', error)
    })

    fetchConnectedDevices().catch(error => {
      console.error('Failed to fetch connected devices', error)
    })

    fetchPackages().catch(error => {
      console.error('Failed to fetch packages', error)
    })

    fetchLots().catch(error => {
      console.error('Failed to fetch lots', error)
    })
  }, [])

  return (
    <DataContext.Provider
      value={{
        dataOk,
        allOrganizations,
        setAllOrganizations,
        getOrganizationById,
        currentOrganization,
        users,
        setUsers,
        getUserById,
        customers,
        setCustomers,
        woods,
        setWoods,
        typologies,
        setTypologies,
        grades,
        setGrades,
        qualities,
        setQualities,
        origins,
        setOrigins,
        sawmills,
        setSawmills,
        measurers,
        setMeasurers,
        fetchData,
        fetchSpecificData,
        getCustomerById,
        getWoodById,
        getTypologyById,
        getGradeById,
        getQualityById,
        getOriginById,
        getSawmillById,
        getMeasurerById,
        licenses,
        setLicenses,
        getLicenseById,
        packages,
        setPackages,
        lots,
        setLots,
        configuredDevices,
        connectedDevices,
        setConnectedDevices,
        defaultMenu,
        setDefaultMenu,
        organizationId,
        setOrganizationId,
        standingUp,
        setCurrentOrganization,
        license,
        setLicense,
        packagesStatistics,
        lotsStatistics,
        statisticsLoaded,
        loadStatistics,
        woodTypes,
        reductionTypes,
        reductionTypeFormulas,
        loadWoodTypes,
        loadReductionTypes,
        woodTypesLoaded,
        reductionTypesLoaded,
        reductionTypesFormulasLoaded,
        loadReductionTypesFormula,
        setWoodTypes,
      }}>
      {children}
    </DataContext.Provider>
  )
}

export function useData() {
  const context = useContext(DataContext)
  if (!context) throw new Error('useData() can only be used inside of DataContext')

  return context
}

export function useDataByPackage(pkg: Package | undefined) {
  const { woods, typologies, grades, qualities, customers } = useData()
  const wood = useMemo(() => {
    if (!pkg) return undefined
    return woods.find(w => w.woodId === pkg.woodId)
  }, [pkg, woods])

  const typology = useMemo(() => {
    if (!pkg) return undefined
    return typologies.find(t => t.typologyId === pkg.typologyId)
  }, [pkg, typologies])

  const grade = useMemo(() => {
    if (!pkg) return undefined
    return grades.find(g => g.gradeId === pkg.gradeId)
  }, [pkg, grades])

  const quality = useMemo(() => {
    if (!pkg) return undefined
    return qualities.find(q => q.qualityId === pkg.qualityId)
  }, [pkg, qualities])

  const customer = useMemo(() => {
    if (!pkg) return undefined
    return customers.find(c => c.customerId === pkg.customerId)
  }, [pkg, customers])

  return { wood, typology, grade, quality, customer }
}

export function useDataByLot(lot: Lot | undefined) {
  const { woods, customers, qualities } = useData()
  const wood = useMemo(() => {
    if (!lot) return undefined
    return woods.find(w => w.woodId === lot.woodId)
  }, [lot, woods])
  const customer = useMemo(() => {
    if (!lot) return undefined
    return customers.find(c => c.customerId === lot.customerId)
  }, [lot, customers])
  const quality = useMemo(() => {
    if (!lot) return undefined
    return qualities.find(g => g.qualityId === lot.qualityId)
  }, [lot, qualities])

  return { wood, customer, quality }
}
