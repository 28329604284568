import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { ListSection, PackageUm, packageUmValues } from '../../types/types'
import PackageUmCard from '../cards/PackageUmCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: PackageUm | undefined) => void
  required?: boolean
  selectedPackageUm?: PackageUm
}

export default function PackageUmSelector({ onChange, selectedPackageUm, ...props }: Props) {
  const { i18n } = useLanguage()

  const [data] = useState<PackageUm[]>(packageUmValues)

  const render = ({ item }: SectionListRenderItemInfo<PackageUm, ListSection<PackageUm>>, onPress?: () => void) => {
    return <PackageUmCard packageUm={item} onPress={onPress} selected={item.value === selectedPackageUm?.value} spacing={['all']} />
  }

  const handleOnChange = (newValue: PackageUm | undefined) => {
    onChange(newValue)
  }

  return (
    <SelectInput
      id={'value'}
      data={data}
      value={selectedPackageUm}
      loading={false}
      refresh={undefined}
      title={i18n.t('PACKAGE_UM')}
      screenTitle={i18n.t('PACKAGE_UM')}
      noDataMessage={i18n.t('NO_PACKAGE_UM')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.value ?? ''}
      deferredTimeout={0}
      disableLoadAfterMount
      autoSelectIfUnique
      {...props}
    />
  )
}
