import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { LayoutChangeEvent, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import PressableIcon from '../../../PressableIcon'
import TABLE_CONSTANTS from '../../constants/TableConstants'
import TableUtils from '../../utils/TableUtils'

interface NewItemRowProps {
  onNewItemPress?: () => void
  onDeleteAllPress?: () => void
  deleteIconVisible?: boolean
  style?: StyleProp<ViewStyle>
  height?: number
  widthArray?: number[]
  flexArray?: number[]
  iconSize?: number
  iconColor?: string
  iconStyle?: StyleProp<ViewStyle>
  onLayout?: (event: LayoutChangeEvent) => void
  backgroundColor?: string
}

export default function NewItemRow({
  style,
  height,
  widthArray,
  flexArray,
  onNewItemPress,
  onDeleteAllPress,
  deleteIconVisible,
  iconColor,
  iconSize,
  iconStyle,
  onLayout,
  backgroundColor,
}: NewItemRowProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const tableWidth = widthArray ? TableUtils().sum(widthArray) : '100%'
  const tableFlex = flexArray ? TableUtils().sum(flexArray) : 1
  const rowHeight = height ?? TABLE_CONSTANTS.defaultRowHeight

  const composedStyle = useMemo(() => {
    const tableStyle: StyleProp<ViewStyle> = {}
    if (flexArray) tableStyle.flex = tableFlex
    if (widthArray) tableStyle.width = tableWidth
    return tableStyle
  }, [flexArray])

  return (
    <IM.View
      style={[
        IMLayout.flex.row,
        {
          backgroundColor: backgroundColor ? backgroundColor : theme.table.rowEven,
          height: rowHeight,
          borderTopColor: theme.table.divider,
        },
        composedStyle,
        styles.newItemRow,
        style,
        deleteIconVisible && styles.deleteIcon,
      ]}
      onLayout={onLayout}>
      {deleteIconVisible && onDeleteAllPress && (
        <PressableIcon
          icon={['fal', 'trash']}
          color={iconColor ?? theme.table.icon}
          size={iconSize ?? 16}
          style={[iconStyle]}
          onPress={onDeleteAllPress}
        />
      )}
      {onNewItemPress && (
        <PressableIcon
          icon={['fal', 'plus']}
          color={iconColor ?? theme.table.icon}
          size={iconSize ?? 16}
          style={[iconStyle]}
          onPress={onNewItemPress}
        />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  newItemRow: { justifyContent: 'flex-end', alignItems: 'center', paddingRight: 10, borderTopWidth: 2 },
  deleteIcon: { justifyContent: 'space-between', paddingLeft: 10 },
})
