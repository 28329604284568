import { CardProps, IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { LanguageType } from '../../types/types'
import CardLeftTitle from '../CardLeftTitle'

export type ExportLanguageCardProps = { exportLanguage: LanguageType } & CardProps

export default function ExportLanguageCard({ exportLanguage, selected, ...cardProps }: ExportLanguageCardProps) {
  const { theme } = useTheme()
  const { i18n } = useLanguage()

  return (
    <IM.Card head={<CardLeftTitle color={selected && theme.primary} />} {...cardProps}>
      <IM.Text>{i18n.t(exportLanguage.label)}</IM.Text>
    </IM.Card>
  )
}
