import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Wood } from '../../apis/apiResponseTypes'
import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types/types'
import WoodCard from '../cards/WoodCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Wood | undefined) => void
  required?: boolean
  selectedWoodId?: number
  showReduction?: boolean
  error?: boolean
}

export default function WoodSelector({ onChange, selectedWoodId, showReduction, ...props }: Props) {
  const { i18n } = useLanguage()

  const { woods, organizationId, setWoods } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(woods, search, ['woodName', 'descriptions', 'woodId', 'woodCode'])

  const render = ({ item }: SectionListRenderItemInfo<Wood, ListSection<Wood>>, onPress?: () => void) => {
    return (
      <WoodCard
        wood={item}
        showReduction={showReduction}
        onPress={onPress}
        selected={item.woodId === selectedWoodId}
        spacing={['bottom', 'horizontal']}
      />
    )
  }

  const handleOnChange = (newValue: Wood | undefined) => {
    onChange(newValue)
  }

  const handleSave = (inputValue: string, reduction?: number) => {
    saveWood(inputValue, reduction).catch(error => {
      console.error('Failed to save customer', error)
    })
  }

  const saveWood = async (inputValue: string, reduction?: number) => {
    try {
      if (organizationId === undefined) return
      await api.postWood({ woodName: inputValue, organizationId: organizationId, reduction: reduction })
      const res = await api.getWoods({ organizationId: organizationId })
      setWoods(res)
    } catch (error) {
      console.error('Failed to save customer', error)
    }
  }

  const selectedWood = useMemo(() => woods?.find(elem => elem.woodId === selectedWoodId), [woods, selectedWoodId])

  return (
    <SelectInput
      id={'woodId'}
      data={filteredData}
      value={selectedWood}
      loading={false}
      refresh={undefined}
      title={i18n.t('WOOD_TYPES')}
      screenTitle={i18n.t('WOOD_TYPES')}
      noDataMessage={i18n.t('NO_WOOD_TYPES')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.woodName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      // onSave={(inputValue: string) => handleSave(inputValue)}
      onSaveWood={(inputValue: string, reduction?: number) => handleSave(inputValue, reduction)}
      showReduction={showReduction}
      searchField="woodName"
      {...props}
    />
  )
}
