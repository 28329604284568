import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'

import { Screen } from '../components/screen/Screen'
import { FormProvider } from '../contexts/FormContext'
import { LotCreationContextProvider } from '../contexts/LotCreationContext'
import TrunkCreateHeader from '../screens/LotCreate/TrunkCreateHeader'
import ExportLotView from '../screens/Lots/ExportLotView'
import { ModalScreenRef } from '../types'
import { Lot } from '../types/types'
import BaseServiceModal from './BaseServiceModal'

export type ExportLotModalProps = {
  controller: ModalController<ExportLotModalControllerData>
}

export type ExportLotModalControllerData = {
  lots: Lot[]
}

export default function ExportLotModal({ controller }: ExportLotModalProps) {
  const ref = createRef<ModalScreenRef>()
  const [isShown, setIsShown] = useState(false)
  const { i18n } = useLanguage()

  const isModal = controller !== undefined

  const handleClose = () => {
    controller.close()
  }

  const onExport = () => {
    controller.close()
  }

  useEffect(() => {
    if (!controller.isShown) setIsShown(false)
  }, [controller.isShown])

  return (
    <BaseServiceModal controller={controller} childRef={ref} onModalShow={() => setIsShown(true)}>
      <LotCreationContextProvider>
        <Screen>
          <Screen.Header goBack={() => handleClose()} backHandlerCallback={handleClose} isModal={isModal} title={i18n.t('EXPORT')} />
          <Screen.Header goBack={handleClose} backHandlerCallback={() => controller.close}>
            <TrunkCreateHeader />
          </Screen.Header>
          <Screen.Content>
            <FormProvider>
              {isShown && (
                <FormProvider>
                  <ExportLotView onExport={onExport} lots={controller.data?.lots} />
                </FormProvider>
              )}
              {!isShown && <IM.LoadingSpinner isVisible={true} />}
            </FormProvider>
          </Screen.Content>
        </Screen>
      </LotCreationContextProvider>
    </BaseServiceModal>
  )
}
