import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { LicenseSelectType, licenseSelectTypeValues, ListSection } from '../../types/types'
import LicenseTypeCard from '../cards/LicenseTypeCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: LicenseSelectType | undefined) => void
  required?: boolean
  selectedLicenseType?: LicenseSelectType
}

export default function LicenseTypeSelector({ onChange, selectedLicenseType, ...props }: Props) {
  const { i18n } = useLanguage()

  const [data] = useState<LicenseSelectType[]>(licenseSelectTypeValues)

  const render = ({ item }: SectionListRenderItemInfo<LicenseSelectType, ListSection<LicenseSelectType>>, onPress?: () => void) => {
    return <LicenseTypeCard licenseType={item} onPress={onPress} selected={item.value === selectedLicenseType?.value} spacing={['all']} />
  }

  const handleOnChange = (newValue: LicenseSelectType | undefined) => {
    onChange(newValue)
  }

  return (
    <SelectInput
      id={'value'}
      data={data}
      value={selectedLicenseType}
      loading={false}
      refresh={undefined}
      title={i18n.t('LICENSE_TYPE')}
      screenTitle={i18n.t('LICENSE_TYPES')}
      noDataMessage={i18n.t('NO_LICENSE_TYPE')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.value}
      deferredTimeout={0}
      disableLoadAfterMount
      autoSelectIfUnique
      hideNoSelectionItem
      {...props}
    />
  )
}
