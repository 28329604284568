import { IMLayout, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { StaticTimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { PickerSelectionState } from '@mui/x-date-pickers/internals'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'react-native-modal'
import { DateTimePickerProps as ReactNativeModalDateTimePickerProps } from 'react-native-modal-datetime-picker'

import TimeUtils from '../utils/TimeUtils'

export type DateTimePickerProps = {
  controller?: ModalController
  minimumDate?: Date
  maximumDate?: Date
} & Partial<ReactNativeModalDateTimePickerProps>

const translations = {
  en: {
    ok: 'Ok',
    cancel: 'Cancel',
    select: 'Select time',
  },
  de: {
    ok: 'Ok',
    cancel: 'Abbrechen',
    select: 'Uhrzeit wählen',
  },
  it: {
    ok: 'Ok',
    cancel: 'Annulla',
    select: 'Seleziona orario',
  },
}

const maxSelectableDate = new Date(2199, 0, 1)
const minSelectableDate = new Date(1899, 0, 1)
export default function DateTimePicker({
  controller,
  isVisible,
  date,
  mode = 'date',
  minimumDate = minSelectableDate,
  maximumDate = maxSelectableDate,
  onChange,
  onConfirm,
  onCancel,
  locale,
}: DateTimePickerProps) {
  const { theme, colorScheme } = useTheme()
  const { language } = useLanguage()

  const texts = useMemo(() => translations[language], [language])
  const [internalDate, setInternalDate] = useState(date)

  const handleDateChange = (newDate: Date | null, selectionState?: PickerSelectionState) => {
    if (newDate === null) return
    // change whole date only if the user has finished selecting
    if (selectionState === 'finish') {
      handleChange(newDate)
    }
    // otherwise, only update the date with the partial selection
    else {
      setInternalDate(newDate)
    }
  }

  const handleChange = (newDate: Date | null) => {
    if (newDate === null) return

    controller?.close()
    onChange?.(newDate)
    onConfirm?.(newDate)
  }

  const handleCancel = () => {
    controller?.close()
    onCancel?.(new Date())
  }

  useEffect(() => {
    if (mode === 'datetime') console.warn("DateTimePicker Web only supports mode 'date' or 'time'.")
  }, [mode])

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorScheme,
          primary: { main: theme.primary, contrastText: 'white' },
          background: {
            default: theme.modal.background,
            paper: theme.modal.background,
          },
          text: {
            primary: theme.text,
            secondary: theme.text,
          },
        },
      }),
    [colorScheme, theme]
  )

  const effectiveMinimumDate = useMemo(() => manageDateLimit(minimumDate, minSelectableDate, 'min'), [minimumDate])
  const effectiveMaximumDate = useMemo(() => manageDateLimit(maximumDate, maxSelectableDate, 'max'), [maximumDate])

  return (
    <Modal isVisible={controller?.isShown} style={{ borderRadius: IMLayout.borderRadius }} onBackdropPress={() => controller?.close()}>
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale ?? TimeUtils.languageToLocale(language)}>
          {mode === 'date' && (
            <DateCalendar
              sx={{ background: theme.modal.background, color: theme.text, borderRadius: IMLayout.borderRadius }}
              value={internalDate}
              minDate={effectiveMinimumDate}
              maxDate={effectiveMaximumDate}
              onChange={handleDateChange}
              views={['month', 'year', 'day']}
            />
          )}
          {mode === 'time' && (
            <StaticTimePicker
              value={internalDate}
              autoFocus
              localeText={{ toolbarTitle: texts.select, okButtonLabel: texts.ok, cancelButtonLabel: texts.cancel }}
              onAccept={handleChange}
              onClose={handleCancel}
            />
          )}
        </LocalizationProvider>
      </ThemeProvider>
    </Modal>
  )
}

function manageDateLimit(date: Date, dateLimit: Date, type: 'min' | 'max') {
  if (type === 'max') {
    if (date) {
      return date > dateLimit ? dateLimit : date
    } else {
      return dateLimit
    }
  } else {
    if (date) {
      return date < dateLimit ? dateLimit : date
    } else {
      return dateLimit
    }
  }
}
