import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef } from 'react'
import { StyleSheet } from 'react-native'

import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type LogoutModalProps = {
  controller: ModalController
  onConfirmClose: () => void
}

export default function ClosePackageLotModal({ controller, onConfirmClose }: LogoutModalProps) {
  const ref = createRef<ModalScreenRef>()
  const { i18n } = useLanguage()

  const handleCloseModal = () => {
    controller.close()
  }

  const handleClose = () => {
    onConfirmClose()
    controller.close()
  }

  return (
    <BaseServiceModal controller={controller} childRef={ref} style={styles.customModalStyle}>
      <IM.View style={styles.container}>
        <IM.Text style={styles.text}>{i18n.t('CLOSE_MEASUREMENT')}</IM.Text>
        <IM.View style={styles.buttonContainer}>
          <IM.Button title={i18n.t('CANCEL')} style={styles.button} onPress={handleCloseModal} />
          <IM.Button title={i18n.t('OK')} style={styles.button} onPress={handleClose} />
        </IM.View>
      </IM.View>
    </BaseServiceModal>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  text: {
    marginTop: 20,
    fontSize: 18,
    textAlign: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    flex: 1,
    marginHorizontal: 10,
  },

  customModalStyle: {
    height: '50%',
    width: '80%',
  },
})
