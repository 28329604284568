import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowDownShortWide as faArrowDownShortWideSolid,
  faArrowUpShortWide as faArrowUpShortWideSolid,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons'
import {
  faAnglesLeft,
  faAnglesRight,
  faArrowDownShortWide,
  faArrowLeft,
  faArrowRotateLeft,
  faAxe,
  faBadgeCheck,
  faBars,
  faBluetooth,
  faBuilding,
  faCalendar,
  faCalendarClock,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloudArrowDown,
  faCloudArrowUp,
  faCube,
  faDeleteLeft,
  faDownToBracket,
  faExpand,
  faEye,
  faEyeSlash,
  faFence,
  faFileCertificate,
  faFilter,
  faFloppyDisk,
  faGear,
  faGrid,
  faHourglassClock,
  faHouse,
  faKeyboard,
  faList,
  faLocationDot,
  faMagnifyingGlass,
  faMinus,
  faPalletBoxes,
  faPen,
  faPersonToDoor,
  faPlus,
  faPrint,
  faQrcode,
  faShareNodes,
  faSitemap,
  faSquare,
  faTicket,
  faToolbox,
  faTrash,
  faTree,
  faTreeDeciduous,
  faTriangleExclamation,
  faUpFromBracket,
  faUserHelmetSafety,
  faXmark,
} from '@fortawesome/pro-light-svg-icons'
import { DefaultTheme, IMStyle, ThemeType } from '@infominds/react-native-components'
import Color from 'color'

import { ThemeColorExpanded } from './types'

library.add(
  faTicket,
  faHouse,
  faGear,
  faTree,
  faCube,
  faQrcode,
  faPersonToDoor,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faArrowLeft,
  faPlus,
  faMinus,
  faCheck,
  faKeyboard,
  faMagnifyingGlass,
  faBluetooth,
  faCalendar,
  faPen,
  faTrash,
  faTriangleExclamation,
  faXmark,
  faDeleteLeft,
  faBadgeCheck,
  faFilter,
  faCloudArrowUp,
  faChevronUp,
  faFileCertificate,
  faBuilding,
  faHourglassClock,
  faUserHelmetSafety,
  faTreeDeciduous,
  faAxe,
  faLocationDot,
  faPalletBoxes,
  faCalendarClock,
  faCloudArrowDown,
  faExpand,
  faShareNodes,
  faPrint,
  faEye,
  faEyeSlash,
  faSitemap,
  faPen,
  faToolbox,
  faAnglesRight,
  faAnglesLeft,
  faBars,
  faArrowDownShortWide,
  faArrowDownShortWideSolid,
  faArrowUpShortWideSolid,
  faFloppyDisk,
  faGrid,
  faList,
  faChartLine,
  faArrowRotateLeft,
  faFence,
  faAxe,
  faSquare,
  faCheckSquare,
  faUpFromBracket,
  faDownToBracket
)

export const themeExpansion: ThemeType<ThemeColorExpanded> = {
  light: {
    ...DefaultTheme.light,
    primary: '#1FBCA4',
    background: '#F0F2F5',
    backgroundSecondary: '#E2E6ED',
    switch: {
      trackColor: {
        true: '#1FBCA4',
        false: '#989898',
      },
    },
    button: {
      ...DefaultTheme.light.button,
      background: '#333B47',
      disabledBackground: '#BCBCBC',
      disabledIcon: IMStyle.palette.white,
      icon: IMStyle.palette.white,
      qualityCheck: '#8E98F6',
    },
    card: {
      ...DefaultTheme.light.card,
      background: '#FFFFFF',
      secondaryBackground: '#535F71',
      cardBackground: {
        active: '#333B47',
        selected: '#272829',
      },
      accent: {
        active: '#333B47',
      },
      button: {
        active: '#535F71',
        inactive: '#D3D3D3',
        icon: {
          active: 'white',
          inactive: '#8B9F95',
        },
      },
    },
    chip: {
      ...DefaultTheme.light.chip,
      border: IMStyle.palette.neutral,
      background: '#EBEBEB',
      active: {
        background: '#535F71',
        border: '#535F71',
        text: DefaultTheme.dark.text,
      },
      text: DefaultTheme.light.text,
      icon: {
        enabled: DefaultTheme.light.text,
        disabled: '#848484',
      },
      installation: IMStyle.palette.tint,
      removal: '#FF5858',
    },
    dropdown: {
      arrow: '#4C4D4D',
    },
    general: {
      info: IMStyle.palette.tint,
      error: '#FF5858',
      warn: '#FFA758',
      image: '#7682F6',
      excel: '#389663',
      word: '#5186D7',
      pdf: '#B30C01',
      text: '#8E8E8E',
      blue: '#1A73E8',
    },
    header: {
      main: {
        background: '#333B47',
        error: '#FF5858',
        text: { primary: IMStyle.palette.white, secondary: '#C3C7C7', placeholder: Color(IMStyle.palette.white).darken(0.26).toString() },
      },
      detail: { background: '#535F71', skeletonBackground: '#627085' },
    },
    inputBox: {
      background: { active: '#fcfcfc', disabled: '#fafafa' },
      border: { active: '#D5D4DC', disabled: '#fafafa', error: '#FF5858' },
    },
    loader: {
      background: '#DEDEDE',
      border: '#D0CECE',
    },
    pressable: {
      ...DefaultTheme.light.pressable,
      disabled: '#BFBFBF',
      highlight: '#F7F7F7',
      animatedHighlight: '#DFF2E8',
    },
    pressableIcon: {
      disabled: '#e7e7e7',
    },
    radioButton: IMStyle.palette.tint,
    settings: { badge: { background: '#E3E7EC' } },
    skeleton: {
      background: '#E5ECF0',
      highlight: '#DDE4E8',
    },
    tabNavigator: {
      ...DefaultTheme.light.tabNavigator,
      focused: {
        ...DefaultTheme.light.tabNavigator.focused,
        icon: '#fff',
        background: '#4CD3BE',
        iconBackground: '#1FBCA4',
        text: '#fff',
        marker: '#1FBCA4',
      },
      unFocused: { icon: '#828282' },
      background: '#FFFFFF',
    },
    drawerNavigator: {
      background: '#535F71', // '#333B47',
      bar: '#333B47',
      hover: '#535F71',
      icon: {
        focused: '#25D07C',
        unFocused: '#DDE4E8',
      },
    },
    textPlaceholder: '#B3B9C2',
    filterTag: {
      default: {
        background: '#FFFFFF',
        border: IMStyle.palette.neutral,
        text: DefaultTheme.light.text,
      },
      active: {
        background: '#535F71',
        border: '#535F71',
        text: DefaultTheme.dark.text,
      },
      header: {
        background: '#FFFFFF',
        border: IMStyle.palette.neutral,
        text: DefaultTheme.light.text,
      },
    },
    moreSheet: {
      background: '',
      navigationElement: {
        background: '',
        selected: '',
      },
      indicator: '',
    },
    table: {
      text: DefaultTheme.light.text,
      icon: DefaultTheme.light.text,
      header: {
        text: DefaultTheme.dark.text,
        background: '#707F95',
        icon: {
          active: DefaultTheme.light.primary,
          inactive: DefaultTheme.dark.text,
        },
      },
      rowEven: '#FFFFFF',
      rowOdd: '#D7DDE6',
      divider: '#535F71',
    },
  },
  dark: {
    ...DefaultTheme.dark,
    primary: '#1FBCA4',
    background: '#18191A',
    backgroundSecondary: '#222425',
    switch: {
      trackColor: {
        true: '#1FBCA4',
        false: DefaultTheme.dark.switch.trackColor.false,
      },
    },
    button: {
      ...DefaultTheme.light.button,
      background: '#424242',
      disabledBackground: '#898989',
      disabledIcon: IMStyle.palette.white,
      icon: IMStyle.palette.white,
      qualityCheck: '#7682F6',
    },
    card: {
      ...DefaultTheme.dark.card,
      secondaryBackground: '#424242',
      background: '#272829',
      cardBackground: {
        active: '#5C5C5C',
        selected: '#818181',
      },
      accent: {
        active: '#424242',
      },
      button: {
        active: '#424242',
        inactive: '#0B3F25',
        icon: {
          active: 'white',
          inactive: '#5C5C5C',
        },
      },
    },
    chip: {
      ...DefaultTheme.dark.chip,
      border: IMStyle.palette.neutral,
      background: '#353535',
      active: {
        background: '#686868',
        border: '#686868',
        text: DefaultTheme.light.text,
      },
      text: DefaultTheme.dark.text,
      icon: {
        enabled: DefaultTheme.light.text,
        disabled: '#9F9F9F',
      },
      installation: IMStyle.palette.tint,
      removal: '#FF5858',
    },
    dropdown: {
      arrow: '#9CA2AD',
    },
    general: {
      info: IMStyle.palette.tint,
      error: '#FF5858',
      warn: '#FFA758',
      image: '#97A1F8',
      excel: '#389663',
      word: '#5186D7',
      pdf: '#F8372B',
      text: '#919192',
      blue: '#1A73E8',
    },
    header: {
      main: { background: '#424242', error: '#FF5858', text: { primary: IMStyle.palette.white, secondary: '#C3C7C7', placeholder: '#676767' } },
      detail: { background: '#202020', skeletonBackground: '#424242' },
    },
    inputBox: {
      background: { active: '#2f2f2f', disabled: '#222222' },
      border: { active: '#2f2f2f', disabled: '#222222', error: '#FF5858' },
    },
    loader: {
      background: '#454545',
      border: '#595959',
    },
    moreSheet: {
      background: '#252526',
      navigationElement: { background: '#333333', selected: Color(IMStyle.palette.tint).darken(0.25).toString() },
      indicator: 'gray',
    },
    pressable: {
      ...DefaultTheme.dark.pressable,
      disabled: '#3C3C3C',
      highlight: '#2B2B2C',
      animatedHighlight: '#23362C',
    },
    pressableIcon: {
      disabled: '#626262',
    },
    radioButton: IMStyle.palette.tint,
    settings: { badge: { background: '#1E2021' } },
    skeleton: {
      background: '#303233',
      highlight: '#424242',
    },
    tabNavigator: {
      ...DefaultTheme.dark.tabNavigator,
      focused: {
        icon: '#fff',
        background: '#01A68C',
        iconBackground: '#1FBCA4',
        text: '#fff',
        marker: '#1FBCA4',
      },
      unFocused: { icon: '#828282' },
      background: '#242526',
    },
    drawerNavigator: {
      background: '#2B2C2E', // '#424242',
      bar: '#18191A',
      hover: '#242526',
      icon: {
        focused: '#1FBCA4',
        unFocused: '#D0D0D0',
      },
    },
    textPlaceholder: '#676767',
    filterTag: {
      default: {
        background: '#272829',
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
      active: {
        background: DefaultTheme.dark.textDetail,
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
      header: {
        background: DefaultTheme.dark.textDetail,
        border: DefaultTheme.dark.textDetail,
        text: DefaultTheme.dark.text,
      },
    },
    table: {
      text: DefaultTheme.dark.text,
      icon: DefaultTheme.dark.text,
      header: {
        text: DefaultTheme.dark.text,
        background: '#464646',
        icon: {
          active: DefaultTheme.dark.primary,
          inactive: DefaultTheme.dark.text,
        },
      },
      rowEven: '#242526',
      rowOdd: '#1B1B1B',
      divider: '#5e5e5e',
    },
  },
}

export const themeWebExpansion: ThemeType<ThemeColorExpanded> = {
  ...themeExpansion,
  light: {
    ...themeExpansion.light,
    header: {
      ...themeExpansion.light.header,
      main: {
        ...themeExpansion.light.header.main,
        background: '#535F71',
      },
      detail: {
        ...themeExpansion.light.header.detail,
        background: themeExpansion.light.header.main.background,
      },
    },
    tabNavigator: {
      ...themeExpansion.dark.tabNavigator,
      focused: {
        ...DefaultTheme.light.tabNavigator.focused,
        icon: '#fff',
        background: '#4CD3BE4f',
        iconBackground: '#1FBCA44f',
        text: '#fff',
        marker: '#1FBCA4',
      },
    },
  },
  dark: {
    ...themeExpansion.dark,
    header: {
      ...themeExpansion.dark.header,
      main: {
        ...themeExpansion.dark.header.main,
        background: '#2B2C2E',
      },
    },
    tabNavigator: {
      ...themeExpansion.dark.tabNavigator,
      focused: {
        icon: '#fff',
        background: '#01A68C4f',
        iconBackground: '#1FBCA44f',
        text: '#fff',
        marker: '#1FBCA4',
      },
    },
  },
}
