import { IM, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { createRef, useEffect, useState } from 'react'

import { Screen } from '../components/screen/Screen'
import { FormProvider } from '../contexts/FormContext'
import { LotCreationContextProvider } from '../contexts/LotCreationContext'
import LotCreateHeader from '../screens/LotCreate/LotCreateHeader'
import LotCreateView from '../screens/LotCreate/LotCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type LotCreateModalProps = {
  controller: ModalController<LotCreateModalControllerData>
  onCreateCallback?: (id: string) => void
  onSaveCallback?: () => void
}

export type LotCreateModalControllerData = {
  tmpId?: string
  editLotId?: number
}

export default function LotCreateModal({ controller, onCreateCallback, onSaveCallback }: LotCreateModalProps) {
  const ref = createRef<ModalScreenRef>()
  const [isShown, setIsShown] = useState(false)
  const { i18n } = useLanguage()

  const isModal = controller !== undefined
  const isEditModeLotId = controller?.data?.editLotId !== undefined
  const isEditModeTmpId = controller?.data?.tmpId !== undefined

  const editMode = isEditModeLotId || isEditModeTmpId

  const handleCreate = (id: string) => {
    onCreateCallback?.(id)
    controller.close()
  }

  const handleClose = () => {
    controller.close()
  }

  const handleSave = () => {
    onSaveCallback?.()
    controller.close()
  }

  useEffect(() => {
    if (!controller.isShown) setIsShown(false)
  }, [controller.isShown])

  return (
    <BaseServiceModal controller={controller} childRef={ref} onModalShow={() => setIsShown(true)}>
      <LotCreationContextProvider>
        <Screen>
          <Screen.Header
            goBack={() => handleClose()}
            backHandlerCallback={handleClose}
            isModal={isModal}
            title={editMode ? i18n.t('EDIT_LOT') : i18n.t('NEW_LOT')}
          />
          <Screen.Header goBack={handleClose} backHandlerCallback={() => controller.close}>
            <LotCreateHeader />
          </Screen.Header>
          <Screen.Content>
            <FormProvider>
              {isShown && (
                <FormProvider>
                  <LotCreateView tmpId={controller.data?.tmpId} editLotId={controller.data?.editLotId} onSave={handleSave} onCreate={handleCreate} />
                </FormProvider>
              )}
              {!isShown && <IM.LoadingSpinner isVisible={true} />}
            </FormProvider>
          </Screen.Content>
        </Screen>
      </LotCreationContextProvider>
    </BaseServiceModal>
  )
}
